import styled from 'styled-components';
import { useEffect, useState } from 'react';
import {
  FaArrowCircleRight,
  FaArrowCircleUp,
  FaRedoAlt,
  FaCog,
} from 'react-icons/fa';
import HomePageGeneratorInput from './HomePageGeneratorInput';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import useHandleSendFluxImageRequest from '../../hooks/images/useHandleSendFluxImageRequest';
import { TALL_PORTRAIT_SIZE } from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';
import { cleanUpPrompt, waitSeconds } from '../../components/helpers';
import { scrollToTop } from '../../hooks/misc/helpers';
import { useNavigate } from 'react-router-dom';
import { HOME_PATH } from '../../pathNames';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import {
  BORDER_COLOR,
  EVEN_LIGHTER_GREY_COLOR,
  LIGHT_GREY_COLOR,
  PRIMARY_COLOR,
} from '../../constants';
import HomePageSettingsModal from '../../components/Modals/HomePageSettingsModal/HomePageSettingsModal';
import { trackHomePageGenerateStarted } from '../../utils/analytics';

interface I {
  inLandingPage?: boolean;
}

const InputSectionInQuickGenerator: React.FC<I> = ({ inLandingPage }) => {
  const navigate = useNavigate();
  const { images, loading, isFirstTimeUser } = useGeneralContext();
  const {
    setSize,
    userPrompt,
    setUserPrompt,
    setHasModifiedPrompt,
  } = useImageGenerationSettingsContext();
  const [isHomePageSettingsModalOpen, setIsHomePageSettingsModalOpen] =
    useState(false);
  const [hasMultipleTabs, setHasMultipleTabs] = useState(false);

  useEffect(() => {
    if (images.length > 0) return;
    setSize(TALL_PORTRAIT_SIZE);
    //const speedToUse = isFirstTimeUser ? 'turbo' : 'adTurbo';
    //setSpeedMode(speedToUse);
  }, [isFirstTimeUser]);

  useEffect(() => {
    const localStorage = window.localStorage;
    localStorage.openPages = Date.now();

    const handleStorage = (e: StorageEvent) => {
      if (e.key === 'openPages') {
        localStorage.pageAvailable = Date.now();
      }
      if (e.key === 'pageAvailable') {
        setHasMultipleTabs(true);
      }
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  const handleSendFluxImageRequest = useHandleSendFluxImageRequest();

  const handleGenerate = async () => {
    if (hasMultipleTabs) {
      alert("Multiple tabs detected. Please close extra tabs to proceed.");
      return;
    }

    const cleanedUpPrompt = cleanUpPrompt(userPrompt);
    setUserPrompt(cleanedUpPrompt);

    if (inLandingPage) {
      navigate(HOME_PATH);
      return;
    }

    handleSendFluxImageRequest();
    trackHomePageGenerateStarted();
    setHasModifiedPrompt(false);
    await waitSeconds(0.5);
    scrollToTop();
  };

  const placeholder = "Describe your image";
  const isEnterDisabled = userPrompt.length === 0;

  const handlePromptChange = (value: string) => {
    const trimmedValue = value.trim();
    setUserPrompt(value);
    const lastImage = images[0];
    setHasModifiedPrompt(!lastImage || lastImage.prompt !== trimmedValue);
  };

  return (
    <>
      <TextAreaWrapper>
        <SettingsIcon onClick={() => setIsHomePageSettingsModalOpen(true)} />
        <HomePageGeneratorInput
          placeholder={placeholder}
          value={userPrompt}
          setValue={handlePromptChange}
          callbackOnEnter={handleGenerate}
        />
        {loading ? (
          <LoadingIndicator differentBgColor />
        ) : (
          <GenerateIcon
            onClick={isEnterDisabled ? () => {} : handleGenerate}
            isDisabled={isEnterDisabled}
            inLandingPage={inLandingPage}
          />
        )}
      </TextAreaWrapper>
      <HomePageSettingsModal
        show={isHomePageSettingsModalOpen}
        setShow={setIsHomePageSettingsModalOpen}
      />
    </>
  );
};

export default InputSectionInQuickGenerator;

const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  align-items: center;
  justify-content: center;
  width: 95%;
  padding: 2px;
  padding-right: 8px;

  border: 1.5px solid ${LIGHT_GREY_COLOR};
  border-radius: 8px;

  transition: border-color 0.2s;

  ${desktopMediaQuery} {
    &:hover {
      border: 1.5px solid ${EVEN_LIGHTER_GREY_COLOR};
    }
  }
`;

interface GenerateIconProps {
  isDisabled: boolean;
  inLandingPage?: boolean;
  onClick: () => void;
}
const GenerateIcon = ({
  isDisabled,
  inLandingPage,
  onClick,
}: GenerateIconProps) => {
  const { hasModifiedPrompt } = useImageGenerationSettingsContext();

  if (inLandingPage) {
    return (
      <GenerateIconStyledLandingPage
        isDisabled={isDisabled}
        onClick={onClick}
      />
    );
  }

  if (!hasModifiedPrompt && !isDisabled) {
    return <RefreshIconStyled onClick={onClick} />;
  }

  return <GenerateIconStyled isDisabled={isDisabled} onClick={onClick} />;
};

const generateIconSharedStyles = `
  font-size: 26px;
  cursor: pointer;
  transition: color 0.3s;


  ${desktopMediaQuery} {
    &:hover {
      color: ${PRIMARY_COLOR};
    }
  }
`;

const GenerateIconStyled = styled(FaArrowCircleUp)<{ isDisabled: boolean }>`
  ${generateIconSharedStyles}
  color: ${({ isDisabled }) =>
    isDisabled ? BORDER_COLOR : EVEN_LIGHTER_GREY_COLOR};
`;

const GenerateIconStyledLandingPage = styled(FaArrowCircleRight)<{
  isDisabled: boolean;
}>`
  ${generateIconSharedStyles}
  color: ${({ isDisabled }) =>
    isDisabled ? BORDER_COLOR : EVEN_LIGHTER_GREY_COLOR};
`;

const RefreshIconStyled = styled(FaRedoAlt)`
  ${generateIconSharedStyles}
  color: ${EVEN_LIGHTER_GREY_COLOR};
  font-size: 22px;
  margin-right: 4px;
`;

const SettingsIcon = styled(FaCog)`
  ${generateIconSharedStyles}
  color: ${EVEN_LIGHTER_GREY_COLOR};
  font-size: 18px;
  margin-left: 8px;
  margin-right: 4px;
`;
