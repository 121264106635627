import styled from 'styled-components';
import { GOLD_COLOR, PRIMARY_TEXT_COLOR } from '../../../constants';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import { GenericSetState, LoggedInUser } from '../../../types';
import { StyledButton } from '../../common/StyledButton';
import { CloseButtonInModal } from '../../common/CloseButtonInModal';
import {
  BenefitListItem,
  ContainerPaymentModal,
  LineInBuyModal,
  Modal,
  ModalText,
  paymentModalContentStylesMobile,
  paymentModalOverlayStyles,
} from './PaymentCommonComponents';
import { FaShoppingCart } from 'react-icons/fa';
import useCreateRedirectUrlForCentrobill from '../../../hooks/useCreateRedirectUrlForCentrobill';
import LoadingIndicator from '../../common/LoadingIndicator';
import { useState } from 'react';

interface I {
  showModal: boolean;
  setShowModal: GenericSetState<boolean>;
}

const GetSubscriptionModal = ({ showModal, setShowModal }: I) => {
  const { loggedInUser, setLoggedInUser } = useLoggedInUserContext();
  const [loadingRedirect, setLoadingRedirect] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const createUrlToRedirectForCentrobill = useCreateRedirectUrlForCentrobill();

  const handleRedirectToSubscription = async () => {
    setLoadingRedirect(true);
    const urlToRedirect = await createUrlToRedirectForCentrobill(
      'goldSubscription',
    );

    if (urlToRedirect) {
      window.location.href = urlToRedirect;
    } else {
      alert('Something went wrong, please try again later');
    }
    setLoadingRedirect(false);
  };

  return (
    <Modal
      isOpen={showModal}
      style={{
        overlay: paymentModalOverlayStyles,
        content: paymentModalContentStylesMobile,
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <ContainerPaymentModal inSubscriptionModal>
        <CloseButtonInModal onClick={handleClose}>X</CloseButtonInModal>
        <TitleText>⭐ Subscription ⭐</TitleText>
        {loggedInUser?.type === 'gold' ? (
          <ManageExistingSub
            loggedInUser={loggedInUser}
            setLoggedInUser={setLoggedInUser}
            handleClose={handleClose}
          />
        ) : (
          <GetGoldContent
            handleGetGold={handleRedirectToSubscription}
            isLoading={loadingRedirect}
          />
        )}
      </ContainerPaymentModal>
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GetGoldContent = ({
  handleGetGold,
  isLoading,
}: {
  handleGetGold: () => void;
  isLoading: boolean;
}) => (
  <GetGoldContentContainer>
    <ModalText>
      <BenefitListItem>Unlimited turbo generations</BenefitListItem>
      <BenefitListItem>Save more images</BenefitListItem>
      <BenefitListItem>Unlimited Chat messages</BenefitListItem>
      <BenefitListItem>Show off with a badge</BenefitListItem>
      <BenefitListItem>...and more!</BenefitListItem>
      <LineInBuyModal />
      <PopularText>UncensoredAI Gold Subscription</PopularText>
      <PricingText />
      <ExtraInfo />
    </ModalText>
    <GetGoldButton onClick={handleGetGold} disabled={isLoading}>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <FaShoppingCart color={GOLD_COLOR} />
          Start Subscription
        </>
      )}
    </GetGoldButton>
  </GetGoldContentContainer>
);
/*
  <GetGoldContentContainer>
    <ModalText>
      Buying subscriptions is temporarily offline. Buy credits to enjoy fast
      image generation
    </ModalText>
  </GetGoldContentContainer>

*/

const PopularText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
  font-size: 16px;
  color: ${PRIMARY_TEXT_COLOR};
  font-weight: bold;

  margin-left: 12px;
`;

const GetGoldContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const PricingText = () => (
  <div
    style={{
      flexDirection: 'column',
    }}
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        flexDirection: 'row', // Changed from 'row' to 'column' to stack the elements vertically
      }}
    >
      <p
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
        }}
      >
        $20
      </p>
      <p>/ month</p>
    </div>
  </div>
);

const ExtraInfo = () => (
  <div
    style={{
      fontSize: '14px',
      paddingLeft: 32,
      paddingRight: 32,
      marginTop: -16,
      marginBottom: -16,
    }}
  >
    <i>(+ taxes)</i>
  </div>
);

const ManageExistingSub = ({
  loggedInUser,
}: {
  loggedInUser: LoggedInUser;
  setLoggedInUser: GenericSetState<LoggedInUser | null>;
  handleClose: () => void;
}) => {
  const getSubscriptionIsValidUntil = () => {
    if (!loggedInUser.lastPaymentDate) return null;
    const lastPaymentDate = new Date(loggedInUser.lastPaymentDate);
    const date = new Date(
      lastPaymentDate.setMonth(lastPaymentDate.getMonth() + 1),
    );
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const subscriptionIsValidUntil = getSubscriptionIsValidUntil();

  return (
    <>
      <ModalText>
        <p>You are a Gold user! 🎉</p>
        <div
          style={{
            fontSize: '16px',
          }}
        >
          Your current payment covers you until{' '}
          <b>{subscriptionIsValidUntil}</b>
          <br />
          <br />
          <>
            Your subscription will be automatically renewed on{' '}
            <b>{subscriptionIsValidUntil}</b>
            <br />
            <br />
            To cancel it, visit this link: <br />{' '}
            <a
              href="https://centrohelp.eu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cancel Subscription
            </a>
            <br />
            In case of any issues, please contact us at hello@uncensoredAI.io
          </>
        </div>
      </ModalText>
    </>
  );
};

export const GetGoldButton = styled(StyledButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-color: ${GOLD_COLOR};
  box-shadow: 2px 2px 4px ${GOLD_COLOR};
`;
const TitleText = styled(ModalText)`
  font-size: 22px;
  margin-bottom: 2px;
`;

export default GetSubscriptionModal;
