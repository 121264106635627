import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { PRIMARY_TEXT_COLOR } from '../../constants';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
  error: string | null;
  copied: boolean;
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, copied: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error: error.message, copied: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error('Error caught in ErrorBoundary:', error, errorInfo);
    this.setState({ error: error.message });
  }

  handleCopy = () => {
    navigator.clipboard.writeText(this.state.error || '').then(() => {
      this.setState({ copied: true });
      setTimeout(() => this.setState({ copied: false }), 7000);
    });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <StyledDiv>
            An unexpected error has occurred 😭 If this issue persists, please
            contact us at hello@uncensoredai.io and include a copy of this error
            message:
          </StyledDiv>

          <ErrorBlock>
            <CopyButton onClick={this.handleCopy}>
              {this.state.copied ? 'Copied!' : 'Copy to clipboard'}{' '}
            </CopyButton>
            Error: {this.state.error}
          </ErrorBlock>
        </div>
      );
    }

    return this.props.children;
  }
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  font-family: 'Helvetica Neue', sans-serif;
  color: ${PRIMARY_TEXT_COLOR};
  font-size: 16px;
  text-align: center;
`;

const ErrorBlock = styled.pre`
  position: relative;
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  background-color: #f0f4f8;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Helvetica Neue', sans-serif;
  color: #333;
  font-size: 16px;
  text-align: left;
  white-space: pre-wrap;
`;

// top right corner
const CopyButton = styled.button`
  position: absolute;

  border: 1px solid black;
  top: 10px;
  right: 10px;
  background-color: #f0f4f8;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  font-family: 'Helvetica Neue', sans-serif;
  color: #333;
`;

export default ErrorBoundary;
