import { Size } from './types';

export const DEFAULT_PROMPT = 'Perfect ass';

// TODO, can be maybe removed
export const DEFAULT_NEGATIVE_PROMPT =
  '((deformed face)), ((disfigured)), ((bad art)), ((deformed)), ((extra limbs)), ((close up)), blurry, (((duplicate))), ((morbid)), ((mutilated)), (out of frame), extra fingers, mutated hands, ((poorly drawn hands)), ((poorly drawn face)), (((mutation))), (((deformed))), blurry, ((bad anatomy)), (watermark:1.2), (disfigured face and eyes:1.1)';

export const DEFAULT_PORN_NEGATIVE_PROMPT =
  '((deformed face)), ((disfigured)), ((bad art)), ((deformed)), (deformed fingers:1.2), (disfigured face and eyes:1.1)';
//"((deformed face)), ((out of frame)), ((extra fingers)), mutated hands, ((poorly drawn hands)), ((poorly drawn face)), (((mutation))), (((deformed))), (((tiling))), blurry, ((bad anatomy)), ((bad proportions)), ((extra limbs)), cloned face, glitchy, ((double torso)), ((extra arms)), ((extra hands)), ((mangled fingers)), (missing lips), ((extra legs)), (extra nipples), watermark";
export const NEGATIVE_PROMPT_FOR_NEW_PHOTO =
  '((deformed face)), ((disfigured)), ((bad art)), ((deformed)), (disfigured face and eyes:1.1)';

export const DEFAULT_URLS = [
  'https://d1okzptojspljx.cloudfront.net/generations/ec94b280-5ef8-414b-ade8-03576fee91e9-0.png',
  'https://d1okzptojspljx.cloudfront.net/generations/d085427a-b43b-4945-ab88-ba8215a46250-0.png',
  'https://d1okzptojspljx.cloudfront.net/generations/c4fe9ec7-1d78-404f-bf21-a4447894a84e-0.png',
];

export const DEFAULT_SIZE: Size = { width: '512', height: '640' };
export const TALL_PORTRAIT_SIZE: Size = { width: '512', height: '768' };

export const PRIMARY_COLOR = '#d707a4'; //"#F787FF"; //"#30AFF3";
export const PRIMARY_COLOR_DIMMED = 'rgba(215, 7, 164, 0.5)';
//export const PRIMARY_COLOR = '#B22222';

export const DARKER_PRIMARY_COLOR = '#d707a3c3';
//d707a4 in rbga = 215, 7, 164

export const GREEN_COLOR = '#00FF00';

export const PRIMARY_TEXT_COLOR = '#F0F0F0';
export const HOVER_COLOR = PRIMARY_TEXT_COLOR;
export const ICON_COLOR = PRIMARY_TEXT_COLOR;

export const SECONDARY_TEXT_COLOR = '#a8a8a8';
export const BORDER_COLOR = SECONDARY_TEXT_COLOR;

export const LIGHTER_GREY_COLOR = '#a3a3a3';

export const EVEN_LIGHTER_GREY_COLOR = '#e3e3e3';

export const LIGHT_GREY_COLOR = '#303134';
export const LIGHTER_GREY_FOR_HOVER_EFFECT = '#1f1a2b';

export const LIGHT_GREY_FOR_TAG = '#36313d6e';

export const DARK_GREY_COLOR = '#1f1a2b';

export const BACKGROUND_COLOR = '#170d28'; // NEW DARK: "#121212";
export const LIGHT_BG_FOR_CARDS = '#2a1b3b'; // for new dark: "#1E1E1E";
export const LIGHTER_BG_FOR_TOAST = LIGHT_BG_FOR_CARDS;

export const GOLD_COLOR = '#FFD700';
export const DARKER_GOLD_COLOR = '#FFC400';

// THIS NEEDS TO BE BE UPDATED WITH EACH NEW DEPLOY TO PREVIEW!
export const previewBaseBaseUrl =
  'https://deploy-preview-72--onlyfakes.netlify.app';

export const PREVIEW_URL_FOR_FUNCTIONS = `${previewBaseBaseUrl}/.netlify/functions`;

export const BASE_URL = 'https://uncensoredAI.io';
export const BASE_URL_FOR_FUNCTIONS = `${BASE_URL}/.netlify/functions`;

export const FEED_BASE_URL = `${BASE_URL}/app/feed`;

export const MAX_WIDTH = 445;

export const DESKTOP_WIDTH = 1360;

export const MS_IN_SECOND = 1000;

export const PICARUS_URL = 'https://picarus.ai';
export const PICARUS_PROFILE_URL = 'https://picarus.ai/app/profile';

export const ANON_NAME_TO_USE = 'Anonymous';
