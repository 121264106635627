import { SpeedModeType } from "./types";

export const SLOW_MODE_ENABLED = false;

// Remember to sync with functions/helpers.ts and SpeedModeSelector, HintToRegisterModal
const waitTimeForSpeedModes: { [key: string]: number } = {
  normal: 240,
  fast: 60,
  adTurbo: 50,
  turbo: 15,
};

export const superv1WaitTime = 18;
export const superv1MiniWaitTime = 17;
export const miniAdTurboWaitTime = 45;

export const waitBeforeSendingMiniAdTurbo = 40;

export const getWaitTimeForSpeedMode = (speedMode: SpeedModeType, modelId?: string) =>
  speedMode === 'adTurbo' && modelId === 'superv1mini' ? miniAdTurboWaitTime : waitTimeForSpeedModes[speedMode];
