import styled from 'styled-components';
import { BACKGROUND_COLOR } from '../../../../constants';
import { CloseButtonInModal } from '../../../common/CloseButtonInModal';
import TransitioningModal from '../../TransitioningModal';
import { useLoggedInUserContext } from '../../../../context/LoggedInUserContextProvider';
import { StyledButton } from '../../../common/StyledButton';

interface ChatFirstTimeTipsModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

const ChatFirstTimeTipsModal = ({
  show,
  setShow,
}: ChatFirstTimeTipsModalProps) => {
  const { loggedInUser } = useLoggedInUserContext();
  const handleClose = () => setShow(false);

  return (
    <StyledModal
      isOpen={show}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 100,
        },
        content: {
          position: 'relative',
          backgroundColor: BACKGROUND_COLOR,
          padding: '16px',
          width: '90vw',
          maxWidth: '500px',
          height: 'auto',
          margin: 'auto',
          marginTop: window.innerWidth < 768 ? '2vh' : '15vh',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <ModalTitle>The Chat ❤️</ModalTitle>
      <CloseButtonInModal onClick={handleClose} />

      <TipsSection>
        <TipItem>
          <TipTitle>💰 Credit Usage</TipTitle>
          <TipContent>
            {loggedInUser
              ? 'Each message costs 0.5 credits'
              : 'You get 5 messages without signing up. Upon sign up you will receive free credits to continue chatting for free'}
          </TipContent>
        </TipItem>

        <TipItem>
          <TipTitle>🤖 Bot Capabilities</TipTitle>
          <TipContent>
            Our bots are in beta and have about 1% of the "brain size" of models
            like ChatGPT. They also have limited memory of your conversation.
          </TipContent>
        </TipItem>

        <TipItem>
          <TipTitle>⚙️ Customize Your Experience</TipTitle>
          <TipContent>
            Use the settings button to change scenario/instructions if you want
            the bot to remember specific details (e.g., roleplay scenarios or
            character traits).
          </TipContent>
        </TipItem>

        <TipItem>
          <TipTitle>💾 Chat Storage</TipTitle>
          <TipContent>
            Chats are currently only saved on your local machine. Clearing your
            browser cache or using incognito mode will result in losing your
            chat history.
          </TipContent>
        </TipItem>
      </TipsSection>

      <StyledButton onClick={handleClose}>OK!</StyledButton>
    </StyledModal>
  );
};

const StyledModal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
  z-index: 3;
`;

const ModalTitle = styled.h2`
  margin: 0 0 24px 0;
  text-align: center;
  font-size: 1.5rem;
`;

const TipsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 16px 0;
`;

const TipItem = styled.div`
  padding: 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
`;

const TipTitle = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 1.1rem;
`;

const TipContent = styled.div`
  font-size: 0.95rem;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.9);
`;

export default ChatFirstTimeTipsModal;
