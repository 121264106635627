import { IoCopy } from 'react-icons/io5';
import styled from 'styled-components';
import { LIGHT_GREY_COLOR } from '../../../../constants';
import { SecondaryButton } from '../../../common/SecondaryButton';

interface CloneChatButtonProps {
  onClone: () => void;
}

export function CloneChatButton({ onClone }: CloneChatButtonProps) {
  return (
    <StyledCloneButton onClick={onClone}>
      <IoCopy size={18} style={{ marginRight: '8px' }} />
      Clone Chat
    </StyledCloneButton>
  );
}

const StyledCloneButton = styled(SecondaryButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: auto;

  border: 1px solid ${LIGHT_GREY_COLOR};
`; 