import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import useUploadImage from '../../../hooks/images/useUploadImage';
import { getIsOnDesktop } from '../../../styleHelpers';

interface Size {
  width: string;
  height: string;
}

/**
 * Checks if the image dimensions are within the allowed range after resizing.
 *
 * @param {HTMLImageElement} img - The image element to check.
 * @returns {boolean} - Returns true if the image dimensions are within the min and max limits, false otherwise.
 */
const areImageDimensionsValid = (img: HTMLImageElement) => {
  const { naturalWidth, naturalHeight } = img;
  const minDimension = 300;
  const maxDimension = 900;

  const aspectRatio = naturalWidth / naturalHeight;

  let width, height;

  if (naturalWidth > naturalHeight) {
    width = Math.min(naturalWidth, maxDimension);
    height = width / aspectRatio;
  } else {
    height = Math.min(naturalHeight, maxDimension);
    width = height * aspectRatio;
  }

  if (width < minDimension || height < minDimension) {
    return false;
  }

  if (width > maxDimension || height > maxDimension) {
    return false;
  }

  return true;
};


function getResizedFigures(
  naturalWidth: number,
  naturalHeight: number,
  maxSize: number = 768, // Set default maxSize to 1080
  divisor: number = 8, // Set divisor to 8
): { width: number; height: number } {
  let width: number;
  let height: number;

  // Determine the scaling factor
  const scalingFactor = maxSize / Math.max(naturalWidth, naturalHeight);

  // Apply scaling factor to both dimensions
  width = naturalWidth * scalingFactor;
  height = naturalHeight * scalingFactor;

  // Adjust dimensions to be divisible by the divisor
  width = width - (width % divisor);
  height = height - (height % divisor);

  // Ensure dimensions don't get scaled up unintentionally
  width = Math.min(width, naturalWidth);
  height = Math.min(height, naturalHeight);

  return { width, height };
}

const maxSize = 768;
const maxFileSize = 15728640;

/**
 * THIS IS PRETTY MUCH JUST MAGIC TO HANDLE THE IMAGE UPLOADING, SORRY NEXT PERSON!
 *
 * Custom React hook for managing the upload of reference images. It handles resizing images to fit within a
 * maximum size limit, converts them to a base64-encoded format, and then uploads them using the useUpload hook.
 * The hook controls the upload process flow, including checking file size constraints, and resizing the image
 * if it exceeds the maximum dimensions. The resizing maintains the aspect ratio of the image. The hook also
 * manages the upload state, indicating when an upload is in progress.
 *
 * @param {function(string): void} setUploadedImageUrl - Callback function to set the URL of the successfully uploaded image.
 * @param {function(Size): void} setSize - Callback function to set the dimensions of the resized image.
 * @returns {{ isUploading: boolean, handleImageUpload: function(React.ChangeEvent<HTMLInputElement>): void }}
 *          An object containing:
 *            - isUploading (boolean): A state variable indicating if the image is currently being uploaded.
 *            - handleImageUpload (function): A function to be invoked with an image input change event. This function
 *              performs validation on the selected file, resizes the image if necessary, converts it to base64, and
 *              then calls a custom image upload function. Post-upload, it updates the state with the URL and size of
 *              the uploaded image.
 */
const useHandleReferenceImageUploading = (
  setUploadedImageUrl: (url: string) => void,
  setSize: (size: Size) => void,
) => {
  const uploadImage = useUploadImage();
  const [isUploading, setIsUploading] = useState(false);

  const handleImageUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;
      if (file.size > maxFileSize) {
        toast.error('File size must be less than 5MB');
        return;
      }
      const img = new Image();

      const url = URL.createObjectURL(file);

      img.src = url;
      img.onload = async () => {
        if (!areImageDimensionsValid(img)) {
          alert('Incorret image dimensions. Please upload a new image. Aspect ratio should not be too high or too low. If you keep having issues, please contact us at hello@uncensoredai.io');
          URL.revokeObjectURL(url); // Clean up memory
          return;
        }
        const { width, height } = getResizedFigures(
          img.naturalWidth,
          img.naturalHeight,
          maxSize,
        );

        setSize({ width: width.toString(), height: height.toString() });

        URL.revokeObjectURL(url);

        setIsUploading(true);
        const base64Image = await toBase64(file);
        const response = await uploadImage(base64Image);
        if (response.status === 200 && response.imageUrl) {
          setUploadedImageUrl(response.imageUrl);
        }
        setIsUploading(false);
      };

      if (!getIsOnDesktop()) {
        window.scrollTo({ top: 850, behavior: 'smooth' });
      }
    },
    [uploadImage, maxSize, maxFileSize],
  );

  const toBase64 = (file: File) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  return {
    isUploading,
    handleImageUpload,
  };
};

export default useHandleReferenceImageUploading;
