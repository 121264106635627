import styled from 'styled-components';
import { BACKGROUND_COLOR } from '../../../../constants';
import { CloseButtonInModal } from '../../../common/CloseButtonInModal';
import TransitioningModal from '../../TransitioningModal';

interface ShowChatImagesModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

const ShowChatImagesModal = ({ show, setShow }: ShowChatImagesModalProps) => {
  const handleClose = () => setShow(false);

  return (
    <Modal
      isOpen={show}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 100,
        },
        content: {
          position: 'relative',
          backgroundColor: BACKGROUND_COLOR,
          padding: '16px',
          width: '90vw',
          maxWidth: '400px',
          height: 'auto',
          margin: 'auto',
          marginTop: '20vh',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <ModalTitle>Choose Character Image</ModalTitle>
      <CloseButtonInModal onClick={handleClose} />

      <ImagesSection>
        <SectionLabel>Coming soon...</SectionLabel>
      </ImagesSection>
    </Modal>
  );
};

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
  z-index: 3;
`;

const ModalTitle = styled.h2`
  margin: 0 0 24px 0;
  text-align: center;
`;

const ImagesSection = styled.div`
  margin: 16px 0;
`;

const SectionLabel = styled.div`
  font-weight: 500;
  margin-bottom: 12px;
`;

export default ShowChatImagesModal; 