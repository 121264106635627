import React, { useEffect } from 'react';
import { SpeedModeType } from '../../../types';
import {
  ContainerForSelect,
  StyledSelectForBelowImage,
} from './SharedComponentsInBelowImage';
import userSettingsInStorage from '../../../localStorage/userSettingsInStorage';
import { FaTachometerAlt } from 'react-icons/fa';
import { getCreditsRequired } from '../../../components/helpers';
import { getWaitTimeForSpeedMode } from '../../../generationConstants';
import { useImageGenerationSettingsContext } from '../../../context/ImageGenerationSettingsProvider';

interface Props {
  speedMode: SpeedModeType;
  setSpeedMode: (speedMode: SpeedModeType) => void;
  wider?: boolean;
}

const SpeedModeSelector: React.FC<Props> = ({
  speedMode,
  setSpeedMode,
  wider,
}) => {
  const { engine } = useImageGenerationSettingsContext();
  useEffect(() => {
    const speedModeFromLocalStorage = userSettingsInStorage().speedMode.get();
    if (speedModeFromLocalStorage) {
      setSpeedMode(speedModeFromLocalStorage as SpeedModeType);
    }
  }, []);

  const handleChange = (e: any) => {
    const newSpeedMode = e.target.value;
    if (!newSpeedMode) {
      console.error('newSpeedMode is undefined');
      return;
    }
    setSpeedMode(newSpeedMode);
    userSettingsInStorage().speedMode.set(newSpeedMode);
  };

  const isSuperEngine =
    engine?.modelId === 'superv1' || engine?.modelId === 'superv1mini';
  const isSuperEngineV1 = engine?.modelId === 'superv1';

  const normalText = `Normal - ${getCreditsRequired(
    'normal',
  )} credit  ~${getWaitTimeForSpeedMode('normal')} sec`;
  const fastText = `Fast - ${getCreditsRequired(
    'fast',
  )} credits ~${getWaitTimeForSpeedMode('fast')} sec`;
  const adTurboText = `🆕 Turbo with ads - 0 credits ~${getWaitTimeForSpeedMode(
    'adTurbo', engine?.modelId
  )} sec`;
  const turboText = `Turbo - ${getCreditsRequired(
    'turbo',
    1,
    engine,
  )} credits ~${getWaitTimeForSpeedMode('turbo')} sec`;

  return (
    <ContainerForSelect wider={wider}>
      <FaTachometerAlt size={20} />
      <StyledSelectForBelowImage value={speedMode} onChange={handleChange}>
        {!isSuperEngine && (
          <>
            <option value={'normal'}>{normalText}</option>
            <option value={'fast'}>{fastText}</option>
          </>
        )}
        {!isSuperEngineV1 && <option value={'adTurbo'}>{adTurboText}</option>}
        <option value={'turbo'}>{turboText}</option>
      </StyledSelectForBelowImage>
    </ContainerForSelect>
  );
};

export default SpeedModeSelector;
