import { useCookies } from 'react-cookie';
import { customFetch } from '../customFetch';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import { getRandomSeed } from '../../components/helpers';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { FakeImage } from '../../types';
import { scrollToTop } from '../misc/helpers';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { addImageToLocalStorage } from '../../localStorage/imageStorage';
import { handlePopUnderAd } from './helpers';
import useHandleDeductCredits from './useHandleDeductCredits';
import { getCreditsRequired } from '../../components/helpers';
import { waitSeconds } from '../../components/helpers';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import { useModalsContext } from '../../context/ModalsContextProvider';
import { superEngineV1Mini } from '../../utils/engineHelpers';
import { getIsOnDesktop } from '../../styleHelpers';
import {
  superv1MiniWaitTime,
  miniAdTurboWaitTime,
  superv1WaitTime,
  waitBeforeSendingMiniAdTurbo,
} from '../../generationConstants';
import {
  trackSuperv1MiniAdTurboStarted,
  trackSuperv1MiniStarted,
  trackSuperv1Started,
} from '../../utils/analytics';

const useHandleSendFluxImageRequest = () => {
  const [{ token }] = useCookies(['token', 'fetchToken']);
  const { loggedInUser } = useLoggedInUserContext();
  const {
    isSeedFrozen,
    setActiveImage,
    setImages,
    setEstimateGenerationReadyTimestamp,
    setGenerationStartedTimestamp,
    setLoading,
    loading,
    isFirstTimeUser,
    firstTimeUserToken,
    setFirstTimeUserToken,
  } = useGeneralContext();
  const {
    userPrompt,
    negativePrompt,
    seedState: seed,
    setSeed,
    size,
    cfg,
    amountToGenerate,
    uploadedImageUrl,
    speedMode,
    engine,
    setEngine,
    hasSeenAdInfoText,
    setHasSeenAdInfoText,
  } = useImageGenerationSettingsContext();
  const handleDeductCredits = useHandleDeductCredits();

  const { setShowHintToRegisterModal, hasClosedHintToRegisterModal } =
    useModalsContext();

  const getSeed = () => {
    if (isSeedFrozen) {
      if (seed > 4294967294) return getRandomSeed();
      if (seed === 0) return getRandomSeed();
      return seed as number;
    }

    const randomSeed = getRandomSeed();
    setSeed(randomSeed);
    return randomSeed;
  };

  const handleSendFluxImageRequest = async (): Promise<number> => {
    // Debug log to track engine state at generation start
    console.log('=== Generation Start ===', {
      engine: engine.modelId,
      speedMode
    });

    // We use effectiveEngine instead of state management to avoid race conditions
    // between different engine modes (adTurbo vs turbo) and ensure consistent engine
    // selection throughout the generation process
    let effectiveEngine = engine;
    if (speedMode === 'adTurbo') {
      effectiveEngine = superEngineV1Mini;
      setEngine(superEngineV1Mini);
      await waitSeconds(1);
    }

    if (loggedInUser?.username !== 'malossi' && loading) {
      toast.error('Please wait for the current generation to complete');
      return 500;
    }

    if (
      !loggedInUser &&
      !hasClosedHintToRegisterModal &&
      speedMode === 'turbo' &&
      !isFirstTimeUser
    ) {
      setShowHintToRegisterModal(true);
      return 500;
    }

    console.log(effectiveEngine.modelId);
    if (speedMode === 'adTurbo') {
      console.log('Before adTurbo engine switch:', {
        currentEngine: effectiveEngine,
        willSwitchTo: superEngineV1Mini
      });
      await waitSeconds(1);
    }

    const isDesktop = getIsOnDesktop();
    if (speedMode === 'adTurbo' && isDesktop) {
      alert(
        "adTurbo temporarily offline for desktop, since ad blockers are able to block the ad, thus we lose money and can't afford it",
      );
      return 500;
    }

    // @ts-ignore
    if (speedMode !== 'turbo' && speedMode !== 'adTurbo') {
      toast.error('Super is only available in Turbo and Ad Turbo modes');
      return 500;
    }

    setLoading(true);
    const now = Date.now();
    setGenerationStartedTimestamp(now);

    const getExpectedWait = () => {
      console.log('Calculating wait time for:', effectiveEngine.modelId);
      if (effectiveEngine.modelId === 'superv1mini') {
        console.log(speedMode);
        return speedMode === 'adTurbo'
          ? miniAdTurboWaitTime * 1000
          : superv1MiniWaitTime * 1000;
      }
      return superv1WaitTime * 1000;
    };

    setEstimateGenerationReadyTimestamp(now + getExpectedWait());

    if (speedMode === 'adTurbo') {
      if (!hasSeenAdInfoText) {
        toast.info(
          'The ads, unfortunately, pay very little, and the servers are very expensive. Due to this we have to show multiple ads to avoid going bankrupt. Sorry!',
          { autoClose: 5000 },
        );
        setHasSeenAdInfoText(true);
        await waitSeconds(5);
      }

      const resultOfAdStuff = handlePopUnderAd(speedMode);

      if (!resultOfAdStuff) {
        return 500;
      }

      await waitSeconds(1);

      const resultOfAdStuff2 = handlePopUnderAd(speedMode);
      if (!resultOfAdStuff2) {
        return 500;
      }

      await waitSeconds(1);

      const resultOfAdStuff3 = handlePopUnderAd(speedMode);
      if (!resultOfAdStuff3) {
        return 500;
      }

      await waitSeconds(1);

      const resultOfAdStuff4 = handlePopUnderAd(speedMode);
      if (!resultOfAdStuff4) {
        return 500;
      }
    }

    const creditsRequired = getCreditsRequired(
      speedMode,
      amountToGenerate,
      effectiveEngine,
    );
    const handleDeductCreditsResult = handleDeductCredits(creditsRequired);

    if (handleDeductCreditsResult !== 'ok') {
      toast.error("You don't have enough credits to use Super");
      await waitSeconds(1);
      setLoading(false);
      return 500;
    }

    if (amountToGenerate > 1) {
      toast.error(
        'Currently only one image can be generated with Super at a time',
      );
      setLoading(false);
      return 500;
    }

    const functionName = 'generateSuperImage';

    const trackId = uuidv4();

    const imageRequestObject = {
      fullPrompt: userPrompt,
      negativePrompt,
      seed: getSeed(),
      size,
      cfg,
      amountToGenerate,
      uploadedImageUrl,
      trackId,
      engine: effectiveEngine, // Using effectiveEngine ensures consistent engine throughout request
      speedMode,
      ...(firstTimeUserToken && { firstTimeUserToken }),
    };

    // Log the final request for debugging server issues
    console.log('Image request:', {
      engine: imageRequestObject.engine.modelId,
      speedMode
    });

    if (speedMode === 'adTurbo') {
      await waitSeconds(2);
      toast.info(
        `You are in queue. The image generation will begin in ${waitBeforeSendingMiniAdTurbo} seconds`,
        { autoClose: (waitBeforeSendingMiniAdTurbo * 1000) / 1.3 },
      );
      await waitSeconds(waitBeforeSendingMiniAdTurbo);
    }

    if (speedMode === 'adTurbo' && effectiveEngine.modelId === 'superv1mini') {
      trackSuperv1MiniAdTurboStarted();
    }
    if (effectiveEngine.modelId === 'superv1mini' && speedMode !== 'adTurbo') {
      trackSuperv1MiniStarted();
    }
    if (effectiveEngine.modelId === 'superv1') {
      trackSuperv1Started();
    }

    const response = await customFetch(functionName, {
      imageRequestObject,
      token,
    });
    console.log('Server response:', {
      status: response.status,
      data: response.data,
      requestedEngine: imageRequestObject.engine.modelId,
      returnedEngine: response.data?.engine?.modelId
    });

    const { data } = response;

    if (!data.url) {
      toast.error('Failed to generate image');
      setLoading(false);
      return 500;
    }

    if (data.queue === 420) {
      await waitSeconds(15);
      alert(
        "You have been banned by the automatic system. If you're sure you didn't break the rules, please send your username to hello@uncensoredai.io. Sorry for the inconvenience!",
      );
      return 500;
    }

    // Update estimation with actual eta from server
    if (data.eta) {
      setEstimateGenerationReadyTimestamp(
        now + getExpectedWait() + data.eta * 1000,
      );
    }

    setFirstTimeUserToken('');

    const imageToAdd: FakeImage = {
      imageUrl: data.url,
      trackId,
      guidanceScale: cfg,
      engine: effectiveEngine,
      negativePrompt,
      prompt: userPrompt,
      seed: imageRequestObject.seed,
      height: Number(size.height),
      width: Number(size.width),
      uploadedImageUrl,
    };

    console.log('Final image data:', {
      engine: imageToAdd.engine,
      modelId: imageToAdd.engine.modelId
    });

    setImages((prevImages) => [...prevImages, imageToAdd]);
    setActiveImage(imageToAdd);
    addImageToLocalStorage(imageToAdd);

    scrollToTop();

    if (userPrompt.includes('(')) {
      toast.info(
        'When using Super engines, avoid using parentheses () as they are not well understood by the AI and may lead to worse results',
        { autoClose: 8000 },
      );
    }

    return 200;
  };

  return handleSendFluxImageRequest;
};

export default useHandleSendFluxImageRequest;
