import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  BORDER_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../../../constants';
import { useLoggedInUserContext } from '../../../../context/LoggedInUserContextProvider';
import { desktopMediaQuery } from '../../../../styleHelpers';
import { AiOutlineCopy } from 'react-icons/ai';
import { toast } from 'react-toastify';

const EarnReferralCreditsSection = () => {
  const { loggedInUser } = useLoggedInUserContext();
  const [showReferralBox, setShowReferralBox] = useState(false);
  const handleClick = () => {
    setShowReferralBox(!showReferralBox);
  };

  useEffect(() => {
    // When the component mounts, disable body scroll
    document.body.style.overflow = 'hidden';

    // When the component unmounts, re-enable body scroll
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <ReferralBoxContainer show={showReferralBox}>
      <ReferralBoxButton onClick={handleClick}>
        Earn Free Credits (click) 💰
      </ReferralBoxButton>
      <ReferralBoxText show={showReferralBox}>
        Earn <b>50 credits</b> for every person who signs up through your link!
        <br />
        <p>It’s easy, either:</p>
        <p>1. Share your unique link:</p>
        <YourReferralLink userId={loggedInUser?.id} />
        <p>OR</p>
        <p>
          2. Share a link to <b>any image on the Feed</b>
        </p>
        Self-sign-ups are a no-go and could lead to a ban. Start spreading the
        word and watch those credits roll in!
      </ReferralBoxText>
    </ReferralBoxContainer>
  );
};

const ReferralBoxContainer = styled.div<{ show: boolean }>`
  padding: 16px;
  background-color: transparent;
  border: 1px dotted ${BORDER_COLOR};
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  max-width: 300px;

  margin-top: 16px;

  margin-bottom: ${({ show }) => (show ? '32px' : '0')};
`;

const ReferralBoxButton = styled.div`
  display: flex;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 16px;

  ${desktopMediaQuery} {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ReferralBoxText = styled.div<{ show: boolean }>`
  color: ${PRIMARY_TEXT_COLOR};

  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: ${({ show }) => (show ? 1 : 0)};
  max-height: ${({ show }) =>
    show ? '1000px' : '0'}; // adjust max-height as needed
  transition: visibility 0s, opacity 0.5s ease-in-out,
    max-height 0.5s ease-in-out;
  transition-delay: ${({ show }) => (show ? '0s' : '0.5s')};
  font-size: 14px;
  overflow: hidden;

  margin-top: ${({ show }) => (show ? '12px' : '0')};

  padding-bottom: ${({ show }) => (show ? '32px' : '0')};
`;

const YourReferralLink = ({ userId }: { userId?: string }) => {
  const link = `https://uncensoredai.io/?r-id=${userId}`;

  const handleClick = () => {
    navigator.clipboard.writeText(link);
    toast.success('Copied to clipboard! 🥳');
  };

  return (
    <LinkContainer onClick={handleClick}>
      <LinkText>{link}</LinkText>
      <AiOutlineCopy size={34} color={PRIMARY_COLOR} />
    </LinkContainer>
  );
};

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  width: 250px;
  padding: 10px;
  border: 1px solid ${BORDER_COLOR};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  cursor: pointer;
  overflow: hidden;
`;

const LinkText = styled.span`
  font-size: 14px;
  color: ${PRIMARY_TEXT_COLOR};
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default EarnReferralCreditsSection;
