import { toast } from 'react-toastify';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { addImageToLocalStorage } from '../../localStorage/imageStorage';
import { FakeImage } from '../../types';
import { customFetch } from '../customFetch';
import useHandleDeductCredits from './useHandleDeductCredits';
import { scrollToTop } from '../misc/helpers';
import useGetUserToken from '../userHandling/useGetUserToken';

const useEnhanceImage = () => {
  const {
    setGenerationStartedTimestamp,
    setEstimateGenerationReadyTimestamp,
    setImages,
    setActiveImage,
    setLoading,
  } = useGeneralContext();
  const handleDeductCredits = useHandleDeductCredits();
  const token = useGetUserToken();

  const enhanceImage = async (image: FakeImage): Promise<void> => {
    if (image.isEnhanced) {
      toast.error('This image has already been enhanced');
      return;
    }
    if (!token) {
      toast.error('You need to be logged in to enhance images');
      return;
    }

    const ok = window.confirm(
      'This experimental feature may take a long time. It costs 5 credits, doubles resolution, and improves details and faces. Proceed?',
    );

    if (!ok) return;
    try {
      const creditsRequired = 5;
      const handleDeductCreditsResult = handleDeductCredits(creditsRequired);
      if (handleDeductCreditsResult !== 'ok') {
        toast.error('Not enough credits!');
        setLoading(false)
        return;
      }

      setLoading(true)

      const now = Date.now();
      setGenerationStartedTimestamp(now);
      const expectedWait = 15 * 1000;
      setEstimateGenerationReadyTimestamp(now + expectedWait);
      scrollToTop();

      const enhancingTrackid = `${image.trackId}-enhanced`;

      const imageToBeEnhanced: FakeImage = {
        ...image,
        trackId: enhancingTrackid,
        isEnhanced: true,
      };

      const functionName = 'enhanceImage';

      const response = await customFetch(functionName, {
        imageToEnhance: imageToBeEnhanced,
        token,
      });

      if (typeof response === 'string') {
        toast.error(
          'Error enhancing image, contact hello@uncensoredai.io for refund of credits',
        );
        setLoading(false);
        return;
      }
      const { enhancedImage, eta } = response;

      if (eta) {
        const etaInMs = eta * 1000;
        const etaDate = new Date(now + etaInMs);
        setEstimateGenerationReadyTimestamp(etaDate.getTime());
      }

      setImages((images) => images.concat(enhancedImage));
      setActiveImage(enhancedImage);
      addImageToLocalStorage(enhancedImage);
      setLoading(false)
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return enhanceImage;
};

export default useEnhanceImage;
