import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { useChatContext } from '../../context/ChatContextProvider';
import { waitSeconds } from '../../components/helpers';
import { ChatMessage, GenericSetState, LoggedInUser } from '../../types';
import useApi from './useApi';
import useHandleDeductCredits from '../images/useHandleDeductCredits';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import { useState } from 'react';
import { useModalsContext } from '../../context/ModalsContextProvider';

const HOW_MANY_MESSAGES_TO_KEEP_IN_MEMORY = 20;
const SECONDS_TO_WAIT = 2.7;
const FREE_MESSAGES = 5;

const transformMessages = (newMessages: ChatMessage[]): ChatMessage[] => {
  return newMessages.slice(-HOW_MANY_MESSAGES_TO_KEEP_IN_MEMORY);
};

const increaseMessageCount = (
  setLoggedInUser: GenericSetState<LoggedInUser | null>,
) => {
  setLoggedInUser((prevUser) => {
    if (!prevUser) return null;
    console.log("prevUser.messageCount", prevUser.messageCount)
    return {
      ...prevUser,
      messageCount: (prevUser.messageCount || 0) + 1,
    };
  });
};

const useSendMessage = () => {
  const [{ token, temporaryUserToken }, setCookie] = useCookies([
    'token',
    'temporaryUserToken',
  ]);
  const { activeChatId, chats, userName } = useChatContext();
  const { loggedInUser, setLoggedInUser } = useLoggedInUserContext();
  const { apiCall } = useApi('sendMessage', 'POST');
  const handleDeductCredits = useHandleDeductCredits();
  const [nonLoggedFreeMessagesCount, setNonLoggedFreeMessagesCount] =
    useState(FREE_MESSAGES);

  const { setIsAuthModalOpen } = useModalsContext();

  const activeChat = chats.find((chat) => chat.id === activeChatId);

  const sendMessage = async (
    newMessages: ChatMessage[],
  ): Promise<ChatMessage | null> => {
    if (!activeChat) {
      toast.error('No active chat selected.');
      return null;
    }

    const handleLoggedInUser = () => {
      if (!loggedInUser) return true;
      const isEvenMessage = (loggedInUser.messageCount || 0) % 2 === 1;
      
      if (!isEvenMessage) {
        const result = handleDeductCredits(1);
        if (result === 'Insufficient credits') {
          toast.error(
            'Insufficient credits. Please purchase more to continue chatting',
          );
          return false;
        }
      }
      
      increaseMessageCount(setLoggedInUser);
      return true;
    };

    const handleNonLoggedInUser = async () => {
      if (nonLoggedFreeMessagesCount <= 0) {
        toast.error(
          "You've run out of free messages. Please sign up to continue chatting",
        );
        await waitSeconds(1);
        setIsAuthModalOpen(true);
        return false;
      }

      if (!temporaryUserToken) {
        toast.info(
          `You have ${nonLoggedFreeMessagesCount} free messages to start with before logging in`,
        );
      }

      setNonLoggedFreeMessagesCount((prev) => prev - 1);
      return true;
    };

    const shouldContinue = loggedInUser
      ? handleLoggedInUser()
      : await handleNonLoggedInUser();
    if (!shouldContinue) return null;

    const { botName, chatScenario } = activeChat;
    const messages = transformMessages(newMessages);

    const body = {
      messages,
      botName,
      userName,
      chatScenario,
      token,
      temporaryUserToken,
    };

    await waitSeconds(SECONDS_TO_WAIT);
    let response = await apiCall(body);
    let receivedMessage = response?.data?.message;
    const updatedTemporaryToken = response?.data?.temporaryUserToken;

    if (updatedTemporaryToken && !loggedInUser) {
      setCookie('temporaryUserToken', updatedTemporaryToken, {
        path: '/',
        secure: true,
        sameSite: 'none',
        maxAge: 172800,
      });
    }

    if (response.status === 402) {
      toast.error('No free messages left. Please signup to continue chatting');
      await waitSeconds(1);
      setIsAuthModalOpen(true);
      return null;
    }

    if (response.status === 403) {
      toast.error(
        'Insufficient credits. Please purchase more to continue chatting',
      );
      return null;
    }

    if (response.status !== 200 || !receivedMessage?.content) {
      await waitSeconds(SECONDS_TO_WAIT);
      response = await apiCall(body);
      receivedMessage = response?.data?.message;

      if (response.status !== 200 || !receivedMessage) {
        toast.error('Something went wrong 😭');
        return null;
      }
    }

    return receivedMessage;
  };

  return sendMessage;
};

export default useSendMessage;
