// none of these are used here, but they are a helper trying to learn how the replaceAll works

import { SpeedModeType } from '../../types';
import { trackAdTurboTriggered } from '../../utils/analytics';

/**
 * Represents a utility class for transforming strings.
 */
class StringTransformer {
  private str: string;

  /**
   * Creates a new instance of the StringTransformer class.
   * @param str The string to be transformed.
   */
  constructor(str: string) {
    this.str = str;
  }

  /**
   * Replaces all occurrences of a specified string with another string.
   * @param find The string to be replaced.
   * @param replace The string to replace the occurrences with.
   * @returns The StringTransformer instance with the replaced string.
   */
  replaceAll(find: string, replace: string): StringTransformer {
    this.str = this.str.replace(new RegExp(find, 'g'), replace);
    return this;
  }

  /**
   * Gets the transformed string.
   * @returns The transformed string.
   */
  value(): string {
    return this.str;
  }
}

export const bigPromptTransform = (prompt: string) => {
  let promptToTransform = prompt;
  if (
    prompt.includes('cheerleader') ||
    prompt.includes('cute') ||
    prompt.includes('small') ||
    prompt.includes('tiny') ||
    prompt.includes('little') ||
    prompt.includes('adorable')
  ) {
    promptToTransform = `((adult)) ${prompt}`;
  }

  return new StringTransformer(promptToTransform)
    .replaceAll('1st grade', '')
    .replaceAll('2nd grade', '')
    .replaceAll('3rd grade', '')
    .replaceAll('tween', '')
    .replaceAll('Taylor.Swift', '')
    .replaceAll('taylor.swift', '')
    .replaceAll('tiny tot', ' ')
    .replaceAll('little sis', '')
    .replaceAll('little sister', '')
    .replaceAll('cute sis', '')
    .replaceAll('cute sister', '')
    .replaceAll('kid', '')
    .replaceAll('very young', '')
    .replaceAll('schooler', '')
    .replaceAll('baby', '')
    .replaceAll('jailbait', '')
    .replaceAll('little sister', '')
    .replaceAll('little sis', '')
    .replaceAll('lil sis', '')
    .replaceAll('lil sister', '')
    .replaceAll('little daughter', '')
    .replaceAll('little daughters', '')
    .replaceAll('very youthful', '')
    .replaceAll('Little girl', '')
    .replaceAll('baby face', '')
    .replaceAll('babyface', '')
    .replaceAll('small girl', '')
    .replaceAll('18yo:', '')
    .replaceAll('tiny chest', '')
    .replaceAll('kid', '')
    .replaceAll('preschool', '')
    .replaceAll('pre-school', '')
    .replaceAll('pre school', '')
    .replaceAll('pre-schooler', '')
    .replaceAll('high school', '')
    .replaceAll('highschool', '')
    .replaceAll('high-school', '')
    .replaceAll('middleschool', '')
    .replaceAll('middle school', '')
    .replaceAll('middle-school', '')
    .replaceAll('pre mature', '')
    .replaceAll('premature', '')
    .replaceAll('pre-mature', '')
    .replaceAll('child', '')
    .replaceAll('less than 18yo', '')
    .replaceAll('less than 18 yo', '')
    .replaceAll('less than 18 years old', '')
    .replaceAll('less than 18', '')
    .replaceAll('toddler', '')
    .replaceAll('adorable', '')
    .replaceAll('2yo', '2yo (adult:1.2)')
    .replaceAll('3yo', '3yo (adult:1.2)')
    .replaceAll('4yo', '4yo (adult:1.2)')
    .replaceAll('5yo', '5yo (adult:1.2)')
    .replaceAll('6yo', '6yo (adult:1.2)')
    .replaceAll('7yo', '7yo (adult:1.2)')
    .replaceAll('8yo', '8yo (adult:1.2)')
    .replaceAll('9yo', '9yo (adult:1.2)')
    .replaceAll('2year', '2year (adult:1.2)')
    .replaceAll('3year', '3year (adult:1.2)')
    .replaceAll('4year', '4year (adult:1.2)')
    .replaceAll('5year', '5year (adult:1.2)')
    .replaceAll('6year', '6year (adult:1.2)')
    .replaceAll('7year', '7year (adult:1.2)')
    .replaceAll('8year', '8year (adult:1.2)')
    .replaceAll('9year', '9year (adult:1.2)')
    .replaceAll('2-year-old', '2-year-old (adult:1.2)')
    .replaceAll('3-year-old', '3-year-old (adult:1.2)')
    .replaceAll('4-year-old', '4-year-old (adult:1.2)')
    .replaceAll('5-year-old', '5-year-old (adult:1.2)')
    .replaceAll('6-year-old', '6-year-old (adult:1.2)')
    .replaceAll('7-year-old', '7-year-old (adult:1.2)')
    .replaceAll('8-year-old', '8-year-old (adult:1.2)')
    .replaceAll('9-year-old', '9-year-old (adult:1.2)')
    .replaceAll('2-year old', '2-year old (adult:1.2)')
    .replaceAll('3-year old', '3-year old (adult:1.2)')
    .replaceAll('4-year old', '4-year old (adult:1.2)')
    .replaceAll('5-year old', '5-year old (adult:1.2)')
    .replaceAll('6-year old', '6-year old (adult:1.2)')
    .replaceAll('7-year old', '7-year old (adult:1.2)')
    .replaceAll('8-year old', '8-year old (adult:1.2)')
    .replaceAll('9-year old', '9-year old (adult:1.2)')
    .replaceAll('2 year old', '2 year old (adult:1.2)')
    .replaceAll('3 year old', '3 year old (adult:1.2)')
    .replaceAll('4 year old', '4 year old (adult:1.2)')
    .replaceAll('5 year old', '5 year old (adult:1.2)')
    .replaceAll('6 year old', '6 year old (adult:1.2)')
    .replaceAll('7 year old', '7 year old (adult:1.2)')
    .replaceAll('8 year old', '8 year old (adult:1.2)')
    .replaceAll('9 year old', '9 year old (adult:1.2)')
    .replaceAll('2.years.old', '2.years.old (adult:1.2)')
    .replaceAll('3.years.old', '3.years.old (adult:1.2)')
    .replaceAll('4.years.old', '4.years.old (adult:1.2)')
    .replaceAll('5.years.old', '5.years.old (adult:1.2)')
    .replaceAll('6.years.old', '6.years.old (adult:1.2)')
    .replaceAll('7.years.old', '7.years.old (adult:1.2)')
    .replaceAll('8.years.old', '8.years.old (adult:1.2)')
    .replaceAll('9.years.old', '9.years.old (adult:1.2)')
    .replaceAll('2-years old', '2-years old (adult:1.2)')
    .replaceAll('3-years old', '3-years old (adult:1.2)')
    .replaceAll('4-years old', '4-years old (adult:1.2)')
    .replaceAll('5-years old', '5-years old (adult:1.2)')
    .replaceAll('6-years old', '6-years old (adult:1.2)')
    .replaceAll('7-years old', '7-years old (adult:1.2)')
    .replaceAll('8-years old', '8-years old (adult:1.2)')
    .replaceAll('9-years old', '9-years old (adult:1.2)')
    .replaceAll('2.years old', '2.years old (adult:1.2)')
    .replaceAll('3.years old', '3.years old (adult:1.2)')
    .replaceAll('4.years old', '4.years old (adult:1.2)')
    .replaceAll('5.years old', '5.years old (adult:1.2)')
    .replaceAll('6.years old', '6.years old (adult:1.2)')
    .replaceAll('7.years old', '7.years old (adult:1.2)')
    .replaceAll('8.years old', '8.years old (adult:1.2)')
    .replaceAll('9.years old', '9.years old (adult:1.2)')
    .replaceAll('2 years old', '2 years old (adult:1.2)')
    .replaceAll('3 years old', '3 years old (adult:1.2)')
    .replaceAll('4 years old', '4 years old (adult:1.2)')
    .replaceAll('5 years old', '5 years old (adult:1.2)')
    .replaceAll('6 years old', '6 years old (adult:1.2)')
    .replaceAll('7 years old', '7 years old (adult:1.2)')
    .replaceAll('8 years old', '8 years old (adult:1.2)')
    .replaceAll('9 years old', '9 years old (adult:1.2)')
    .replaceAll('.years.old', ' years old')
    .replaceAll('2.yo', '2.yo (adult:1.2)')
    .replaceAll('3.yo', '3.yo (adult:1.2)')
    .replaceAll('4.yo', '4.yo (adult:1.2)')
    .replaceAll('5.yo', '5.yo (adult:1.2)')
    .replaceAll('6.yo', '6.yo (adult:1.2)')
    .replaceAll('7.yo', '7.yo (adult:1.2)')
    .replaceAll('8.yo', '8.yo (adult:1.2)')
    .replaceAll('9.yo', '9.yo (adult:1.2)')
    .replaceAll('2 yo', '2 yo (adult:1.2)')
    .replaceAll('3 yo', '3 yo (adult:1.2)')
    .replaceAll('4 yo', '4 yo (adult:1.2)')
    .replaceAll('5 yo', '5 yo (adult:1.2)')
    .replaceAll('6 yo', '6 yo (adult:1.2)')
    .replaceAll('7 yo', '7 yo (adult:1.2)')
    .replaceAll('8 yo', '8 yo (adult:1.2)')
    .replaceAll('9 yo', '9 yo (adult:1.2)')
    .replaceAll('10', '18')
    .replaceAll('11', '18')
    .replaceAll('12', '18')
    .replaceAll('13', '18')
    .replaceAll('14', '18')
    .replaceAll('15', '18')
    .replaceAll('16', '18')
    .replaceAll('17', '18')
    .replaceAll('18 yo:1.5', '18 yo')
    .replaceAll('18 yo:1.4', '18 yo')
    .replaceAll('18 yo:1.3', '18 yo')
    .replaceAll('18 yo:1.2', '18 yo')
    .replaceAll('18 yo:1.1', '18 yo')
    .replaceAll('18yo:1.5', '18yo')
    .replaceAll('18yo:1.4', '18yo')
    .replaceAll('18yo:1.3', '18yo')
    .replaceAll('18yo:1.2', '18yo')
    .replaceAll('18yo:1.1', '18yo')
    .replaceAll('18 years old:1.5', '18 years old')
    .replaceAll('18 years old:1.4', '18 years old')
    .replaceAll('18 years old:1.3', '18 years old')
    .replaceAll('18 years old:1.2', '18 years old')
    .replaceAll('18 years old:1.1', '18 years old')
    .replaceAll('18 year old:1.5', '18 year old')
    .replaceAll('18 year old:1.4', '18 year old')
    .replaceAll('18 year old:1.3', '18 year old')
    .replaceAll('18 year old:1.2', '18 year old')
    .replaceAll('18 year old:1.1', '18 year old')
    .replaceAll('18 year-old:1.5', '18 year-old')
    .replaceAll('18 year-old:1.4', '18 year-old')
    .replaceAll('18 year-old:1.3', '18 year-old')
    .replaceAll('18 year-old:1.2', '18 year-old')
    .replaceAll('18 year-old:1.1', '18 year-old')
    .replaceAll('18-year-old:1.5', '18-year-old')
    .replaceAll('18-year-old:1.4', '18-year-old')
    .replaceAll('18-year-old:1.3', '18-year-old')
    .replaceAll('18-year-old:1.2', '18-year-old')
    .replaceAll('18-year-old:1.1', '18-year-old')
    .replaceAll('((18 yo))', '18 yo')
    .replaceAll('((18 years old))', '18 years old')
    .replaceAll('((18 year old))', '18 year old')
    .replaceAll('((18 year-old))', '18 year-old')
    .replaceAll('((18-year-old))', '18-year-old')
    .replaceAll('((18yo))', '18 yo')
    .replaceAll('little cutie', '')
    .replaceAll('little cute', '')
    .replaceAll('Preschoolchild', '')
    .replaceAll('preschoolchild', '')
    .replaceAll('child', '')
    .replaceAll('younthful', '')
    .replaceAll('young', '')
    .replaceAll('youthful', '')
    .replaceAll('tiny cute', '')
    .replaceAll('junior', '')
    .replaceAll('Junior', '')
    .replaceAll('vernal', '')
    .replaceAll('ephebic', '')
    .replaceAll('babe face', '')
    .replaceAll('babe-face', '')
    .replaceAll('tiny, miniature, cute', '')
    .replaceAll('adolescent', '')
    .replaceAll('small little', '')
    .replaceAll('playground', '')
    .replaceAll('adorably cute', '')
    .replaceAll('teen', '')
    .replaceAll('cute little', '')

    .replaceAll('(petite.1:2)', '(petite')
    .replaceAll('(petite.1:3)', '(petite')
    .replaceAll('(petite.1:4)', '(petite')
    .replaceAll('(petite.1:5)', '(petite')
    .replaceAll('(((petite)))', '(petite')
    .replaceAll('((((petite))))', '(petite')

    .replaceAll('(innocent:1.2)', '(innocent')
    .replaceAll('(innocent:1.3)', '(innocent')
    .replaceAll('(innocent:1.4)', '(innocent')
    .replaceAll('(innocent:1.5)', '(innocent')
    .replaceAll('(((innocent)))', '(innocent')
    .replaceAll('((((innocent))))', '(innocent')
    .value();
};

export const handlePopUnderAd = (speedMode: SpeedModeType) => {
  if (speedMode === 'adTurbo') {
    trackAdTurboTriggered();
    const adLink = 'https://diagramjawlineunhappy.com/2038954/';
    const popup = window.open(adLink, '_blank');

    if (popup === null || typeof popup === 'undefined') {
      alert('Please disable your popup blocker to proceed.');
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};
