import styled from 'styled-components';
import ExampleImagesCarousel, {
  EditYourImagesExamples,
} from './ExampleImagesCarousel';
import {
  TextContainerInGrid,
  FeaturesGrid,
  IconContainerInGrid,
} from './FeaturesGrid';
import {
  Section,
  ExampleImage,
  TitleTextWithGradient,
} from './CommonComponents';
import Instructions from './Instructions';
import { BORDER_COLOR } from '../../constants';
import FAQ from './FAQ';

import Footer from '../../components/Footer/Footer';
import { useEffect, useState } from 'react';
import TermsModal from '../../components/Modals/TermsModal';
import { useNavigate } from 'react-router-dom';
import TopBarInLandingPage from '../../components/common/TopBar/TopBarInLandingPage';
import NewCTAButton from './NewCTAButton';
import TitlesSection from './TitlesSection';
import TryItOutSection from './TryItOutSection';
import CommunityImagesSection from './CommunityImagesSection';
import SEOSection from './SEOSection';
//import QuickGeneratorCTA from './QuickGeneratorCTA';   currently not good for bounce rate lol
import FirstTimeUserStorage from '../../localStorage/firstTimeUserStorage';
import LoadingToShowWhileRedirecting from './LoadingToShowWhileRedirecting';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import Logo from '../../components/common/TopBar/Logo';
import { desktopMediaQuery } from '../../styleHelpers';
import SocialProofStars from './SocialProofStars';
import { HOME_PATH } from '../../pathNames';

const LandingPage = () => {
  const { hasVisitedMainApp } = useGeneralContext();
  const [isStandaloneMode, setIsStandaloneMode] = useState(true);
  const [showLogo, setShowLogo] = useState(false);
  // hackish way to redirect to app and not show landing page on "app"
  useEffect(() => {
    const isStandalone = () => {
      return (
        (window.navigator as any).standalone === true ||
        window.matchMedia('(display-mode: standalone)').matches
      );
    };

    if (isStandalone()) {
      window.location.href = '/app';
    } else {
      setIsStandaloneMode(false);
    }
  }, []);

  useEffect(() => {
    const firstTimeUserStorage = new FirstTimeUserStorage();
    const hasVisitedBefore = firstTimeUserStorage.hasChecked();
    // if they have already visited this session, then allow them to go to landing page
    if (hasVisitedMainApp) return;

    // if first time user, show landing page
    if (!hasVisitedBefore) return;

    // redirect to app itself immediately if returning user
    setShowLogo(true);
    navigate(HOME_PATH);
  }, []);

  useEffect(() => {
    // Add styles via a hackish way
    const styleLink = document.createElement('link');
    styleLink.rel = 'stylesheet';
    styleLink.href = '/LandingPage.css';
    document.head.appendChild(styleLink);

    // remove when not in landing page
    return () => {
      document.head.removeChild(styleLink);
    };
  }, []);

  const [showFirstTimeModal, setShowFirstTimeModal] = useState(false);

  const navigate = useNavigate();
  const navigateToContentPolicy = () => {
    navigate('/content-policy');
  };

  const navigateToDisputeResolution = () => {
    navigate('/dispute-resolution');
  };

  useEffect(() => {
    //@ts-ignore automatically zoom out to fix mobile issues
    document.body.style.zoom = 1;

    window.scrollTo(0, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAcceptAndClose = () => {
    localStorage.setItem('acceptedTerms', 'true');
    setShowFirstTimeModal(false);
  };

  if (isStandaloneMode) {
    return null;
  }

  if (showLogo) {
    return <LoadingToShowWhileRedirecting />;
  }

  const HIDE_STUFF = true;

  return (
    <>
      <Container>
        <TopBarInLandingPage />

        <FirstViewContainer className="fullViewResponsiveContainer">
          <Logo inLandingPage />
          <TitlesSection />
          <NewCTAButton />
        </FirstViewContainer>

        <SectionSeparator />

        <SEOSection />

        <SocialProofStars />

        <NewCTAButton />

        <SectionSeparator />

        <CommunityImagesSection />

        <SectionSeparator />

        <ExampleImagesCarousel />

        <NewCTAButton />

        <SectionSeparator />

        <TryItOutSection />

        <SectionSeparator />

        <EditYourCreations />

        <NewCTAButton />

        <SectionSeparator />

        {!HIDE_STUFF && (
          <>
            <Instructions />
            <NewCTAButton />
          </>
        )}

        <SectionSeparator />

        <JoinTheBest />

        <NewCTAButton />

        <SectionSeparator />

        <FAQ />

        <SectionSeparator />

        <Footer
          setShowFirstTimeModal={setShowFirstTimeModal}
          navigateToContentPolicy={navigateToContentPolicy}
          navigateToDisputeResolution={navigateToDisputeResolution}
        />
      </Container>
      <TermsModal
        showModal={showFirstTimeModal}
        handleAcceptAndClose={handleAcceptAndClose}
      />
    </>
  );
};

/*
<SocialButtonsContainer>
<DiscordLogo />
<RedditLogo />
<TwitterLogo />
</SocialButtonsContainer>

const SocialButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  margin-bottom: 16px;
`;
*/

const JoinTheBest = () => (
  <Section as="article">
    <TitleTextWithGradient>Join 500k monthly users</TitleTextWithGradient>
    <ExampleImage src={'example5.jpg'} small />
    <FeaturesGrid>
      <div>
        <IconContainerInGrid>🌍</IconContainerInGrid>
        <TextContainerInGrid>500k+ users </TextContainerInGrid>
      </div>

      <div>
        <IconContainerInGrid>💯</IconContainerInGrid>
        <TextContainerInGrid>50m+ generated images </TextContainerInGrid>
      </div>

      <div>
        <IconContainerInGrid>💫</IconContainerInGrid>
        <TextContainerInGrid>300k+ posts</TextContainerInGrid>
      </div>

      <div>
        <IconContainerInGrid>🎨</IconContainerInGrid>
        <TextContainerInGrid>Realistic, Anime, Digital...</TextContainerInGrid>
      </div>
    </FeaturesGrid>
  </Section>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GetGoldSection = () => (
  <Section as="article">
    <TitleTextWithGradient>OnlyFakes Gold</TitleTextWithGradient>
    <ExampleImage src={'example6.jpg'} />
    <FeaturesGrid>
      <div>
        <IconContainerInGrid>⚡</IconContainerInGrid>
        <TextContainerInGrid>Lightning fast generation</TextContainerInGrid>
      </div>

      <div>
        <IconContainerInGrid>⭐</IconContainerInGrid>
        <TextContainerInGrid>Unlimited credits</TextContainerInGrid>
      </div>

      <div>
        <IconContainerInGrid>💯</IconContainerInGrid>
        <TextContainerInGrid>Multiple images at once </TextContainerInGrid>
      </div>

      <div>
        <IconContainerInGrid>✋</IconContainerInGrid>
        <TextContainerInGrid>Cancel anytime</TextContainerInGrid>
      </div>
    </FeaturesGrid>
  </Section>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UnleashYourCreativity = () => (
  <Section as="article">
    <TitleTextWithGradient>Unleash your imagination</TitleTextWithGradient>
    <ExampleImage src={'example7.jpg'} />
    <FeaturesGrid>
      <div>
        <IconContainerInGrid>📷</IconContainerInGrid>
        <TextContainerInGrid>Share your images </TextContainerInGrid>
      </div>

      <div>
        <IconContainerInGrid>💡</IconContainerInGrid>
        <TextContainerInGrid>Get inspiration from Feed</TextContainerInGrid>
      </div>

      <div>
        <IconContainerInGrid>🥇</IconContainerInGrid>
        <TextContainerInGrid>
          Take part in competitions on{' '}
          <a
            style={{
              color: 'inherit',
            }}
            href="https://discord.gg/eCkrtmb79j"
            target="_blank"
          >
            Discord
          </a>
        </TextContainerInGrid>
      </div>

      <div>
        <IconContainerInGrid>🎓</IconContainerInGrid>
        <TextContainerInGrid>
          Master the art of AI generation
        </TextContainerInGrid>
      </div>
    </FeaturesGrid>
  </Section>
);

const EditYourCreations = () => (
  <Section as="article">
    <TitleTextWithGradient>Try different scenarios</TitleTextWithGradient>
    <EditYourImagesExamples />

    <IconContainerInGrid>🎨</IconContainerInGrid>
    <TextContainerInGrid>
      Try different backgrounds, hairstyles, body types, clothes, tattoos,
      hairstyles...
    </TextContainerInGrid>
  </Section>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95vw;
  margin: 8px;
  margin-top: 16px;
`;

const FirstViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  margin-top: 60px;

  ${desktopMediaQuery} {
    height: 90vh;
    margin-top: 0;
  }
`;

const SectionSeparator = styled.div`
  height: 8px;
  width: 100%;
  border-bottom: 0.5px dotted ${BORDER_COLOR};

  margin-top: 32px;
  margin-bottom: 16px;

  ${desktopMediaQuery} {
    margin-left: 5vw;
  }
`;

export default LandingPage;
