import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { GenericSetState, InfoText } from '../../types';
import { FiCopy } from 'react-icons/fi';
import {
  BACKGROUND_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../constants';

type SimpleModalProps = {
  showModal: boolean;
  setShowModal: GenericSetState<boolean>;
  infoText?: InfoText;
};

const ImageInfoModal: React.FC<SimpleModalProps> = ({
  showModal,
  setShowModal,
  infoText,
}) => {
  if (!infoText) return null;
  const { prompt, negativePrompt, style, engine, seed, guidanceScale } =
    infoText;
  
  const isSuperEngine = engine?.toLowerCase().includes('super');
  
  const handleCopy = () => {
    if (isSuperEngine) {
      navigator.clipboard.writeText(
        `Prompt: ${prompt}\nStyle: ${style}\nEngine: ${engine}`,
      );
    } else {
      navigator.clipboard.writeText(
        `Prompt: ${prompt}\nNegative Prompt: ${negativePrompt}\nStyle: ${style}\nEngine: ${engine}\nSeed: ${seed}\nCFG: ${guidanceScale}`,
      );
    }
  };

  return (
    <StyledModal
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 100,
        },
        content: {
          backgroundColor: BACKGROUND_COLOR,
          color: PRIMARY_TEXT_COLOR,
          opacity: 0.99,
          border: 'none',
          borderRadius: '16px',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={() => setShowModal(false)}
    >
      <CloseButton onClick={() => setShowModal(false)}>&times;</CloseButton>
      <ModalContents>
        <b>Prompt:</b> {prompt}
        <br />
        {!isSuperEngine && (
          <>
            <b>Negative Prompt:</b> {negativePrompt}
            <br />
          </>
        )}
        <b>Style:</b> {style}
        <br />
        <b>Engine:</b> {engine}
        <br />
        {!isSuperEngine && (
          <>
            <b>Seed:</b> {seed}
            <br />
            <b>CFG:</b> {guidanceScale}
          </>
        )}
      </ModalContents>
      <CopyButton onClick={handleCopy} />
    </StyledModal>
  );
};

const CopyButton = styled(FiCopy)`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    color: ${PRIMARY_COLOR};
  }
`;

const ModalContents = styled.p`
  overflow-y: scroll;
  max-height: 65vh;
  width: 70vw;
  max-width: 400px;
  margin: 24px;
  font-size: 12px;
`;

const StyledModal = styled(ReactModal)`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  border-radius: 10px;
  z-index: 50;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;
export default ImageInfoModal;
