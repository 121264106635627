import { useNavigate } from 'react-router-dom';
import { useImagesContext } from '../../context/ImagesContextProvider';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import { FakeImage, FeedChannel, ImageToShare, SharedImage } from '../../types';
import { v4 as uuidv4 } from 'uuid';
import { useCookies } from 'react-cookie';
import {
  checkCombinationsForBlocking,
  checkPromptForAllCelebs,
  checkPromptForBlocking,
} from '../../utils/clientSideBlocking';
import { FEED_PATH } from '../../pathNames';
import { toast } from 'react-toastify';
import useSetDisplayName from '../userHandling/useSetDisplayName';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { getBaseUrlForFetching } from '../../App';
import { headers } from '../misc/helpers';
import useFeedChannels from '../feed/useFeedChannels';
import { useModalsContext } from '../../context/ModalsContextProvider';

interface Returns {
  status: number;
}

type ShareImageParams = {
  imageToBePosted: FakeImage;
  title?: string;
  channel?: FeedChannel;
  newChannelName?: string;
};

// Remember to change in shareImage as well!
const allowedToShareEveryMs = 1000 * 60 * 2; // 2 minutes

const useShareImage = () => {
  const { loggedInUser, setLoggedInUser } = useLoggedInUserContext();
  const {
    sharedImages,
    setSharedImages,
    lastSharedDate,
    setLastSharedDate,
    albumToBePosted,
  } = useImagesContext();
  const { setShowCreateNewPostModal } = useGeneralContext();
  const { setIsAuthModalOpen } = useModalsContext();
  const { setDisplayName } = useSetDisplayName();

  const { updateFeedChannelsInCache } = useFeedChannels();

  const navigate = useNavigate();

  // TODO: this is actually "cookies" and inside is .token. The destructure is done in serverless function which is stupid.
  const [token] = useCookies(['token']);

  const shareImage = async ({
    imageToBePosted,
    title,
    channel,
    newChannelName,
  }: ShareImageParams): Promise<Returns> => {
    if (!loggedInUser) {
      alert('You need to log in to share images');
      setIsAuthModalOpen(true);
      return { status: 400 };
    }

    if (!canShareImage(imageToBePosted, sharedImages, lastSharedDate)) {
      return { status: 400 };
    }

    if (!loggedInUser.displayName) {
      alert(
        'Please set a display name for others to see. You can change it anytime in your profile',
      );
      await setDisplayName();
    }

    const imageToShare: ImageToShare = {
      image: imageToBePosted,
      id: uuidv4(),
      title,
      channel,
      newChannelName,
      amountOfRemixes: 0,
      amountOfReports: 0,
      comments: [],
      sharedAt: new Date(),
      tags: [],
    };

    if (albumToBePosted && albumToBePosted.length > 1) {
      // remove the imageToBePosted from albumToBePosted to avoid it being there twice
      const albumToBePostedWithoutFirstImage = albumToBePosted.filter(
        (image) => image.trackId !== imageToBePosted.trackId,
      );
      // the album also includes the first image that's being posted
      imageToShare.album = [imageToBePosted, ...albumToBePostedWithoutFirstImage];
    }

    const functionName = 'shareImage';
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    const response = await fetch(fetchUrl, {
      method: 'POST',
      body: JSON.stringify({ imageToShare, token }),
      headers,
    });

    const { status } = response;
    if (status === 403) {
      alert(
        'You have been blocked from posting images due to too many reports. Contact hello@uncensoredai.io if you think this is a mistake',
      );
      return { status };
    }

    if (status !== 200) {
      toast.error('Something went wrong. Try refreshing the page');
      return { status };
    }

    const responseJson = await response.json();
    const postedImage = responseJson.postedImage as SharedImage;

    if (!postedImage) {
      toast.error('Something went wrong. Try again later');
      return { status };
    }

    if (newChannelName) {
      updateFeedChannelsInCache();
    }

    const imageId = postedImage.id;

    const newSharedImages = [postedImage, ...sharedImages];
    setSharedImages(newSharedImages);
    const newLoggedInUserObject = {
      ...loggedInUser,
      sharedImages: [imageId, ...loggedInUser.sharedImages],
    };
    setLoggedInUser(newLoggedInUserObject);

    toast.success('Image shared!');

    setLastSharedDate(new Date());

    setShowCreateNewPostModal(false);

    const urlOfNewPost = `${FEED_PATH}?id=${imageId}&imageUrl=${postedImage.image.imageUrl}`;
    console.log({ urlOfNewPost });
    // FeedImagePage.tsx
    navigate(urlOfNewPost);

    return { status };
  };
  return shareImage;
};

export default useShareImage;

const canShareImage = (
  image: FakeImage,
  sharedImages: SharedImage[],
  lastSharedDate: Date | null,
): boolean => {
  if (!image.prompt) {
    window.alert('No image to share');
    return false;
  }

  const isBadPrompt = checkPromptForBlocking(image.prompt);
  if (isBadPrompt) {
    alert(`You can't share this image`);
    return false;
  }

  const isCelebPrompt = checkPromptForAllCelebs(image.prompt);
  if (isCelebPrompt) {
    alert(`You can't share this image`);
    return false;
  }

  const shouldBlockForCombination = checkCombinationsForBlocking(image.prompt);
  if (shouldBlockForCombination) {
    alert(`You can't share this image`);
    return false;
  }

  const isShared = sharedImages.some(
    (sharedImage) => sharedImage.image.trackId === image.trackId,
  );
  if (isShared) {
    alert('You already shared this image');
    return false;
  }

  const isRemixing = !!image.remixing;
  if (isRemixing) {
    toast.error("You can't share someone else's image");
    return false;
  }

  const isImg2Img = !!image?.uploadedImageUrl && !image.isCreatingVariations;
  if (isImg2Img) {
    toast.error(
      "You can't share images you have upload edited yet. Check back later!",
    );
    return false;
  }

  const isFromFeed = !!image.sharedImageRefId;
  if (isFromFeed) {
    toast.error("You can't share an image from the feed");
    return false;
  }

  // compare dateTime now and lastSharedDate and see if it's been less than allowedToShareEveryMs
  const timeWhenAllowedToShare = new Date(
    lastSharedDate ? lastSharedDate.getTime() + allowedToShareEveryMs : 0,
  );

  if (timeWhenAllowedToShare > new Date()) {
    alert(
      `You can only share an image every ${
        allowedToShareEveryMs / 1000 / 60
      } minutes`,
    );
    return false;
  }
  return true;
};
