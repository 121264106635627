import styled from 'styled-components';

import { toast } from 'react-toastify';
import useSendMessage from '../../hooks/chat/useSendMessage';
import { ChatMessage } from '../../types';
import { useChatContext } from '../../context/ChatContextProvider';
import {
  userRole,
  HIDDEN_TYPE_MSG,
  ONBOARDING_TYPE_MSG,
  USER_MESSAGE_COLOR,
  BOT_MESSAGE_COLOR,
  FIRST_HIDDEN_MESSAGE,
} from '../../chatConstants';
import { fadeInWithBounce } from '../../components/ImageStuff/animations';
import { PRIMARY_TEXT_COLOR } from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';
import ActionButtonsInMessage from './ActionButtonsInMessage';

interface MessageBubbleProps {
  message: ChatMessage;
  isLastMessage?: boolean;
  //timestamp
}

const MessageBubble = ({ message, isLastMessage }: MessageBubbleProps) => {
  const {
    activeChatId,
    chats,
    updateChat,
    botIsTyping,
    setBotIsTyping,
    userName,
  } = useChatContext();

  const activeChat = chats.find((chat) => chat.id === activeChatId);

  if (!activeChat) {
    return null;
  }

  const { botName, messages } = activeChat;

  const sendMessage = useSendMessage();
  const isUser = message.role === 'user';
  const roleToRender = isUser ? userName : botName;

  const setMessages = (newMessages: ChatMessage[]) => {
    updateChat(activeChatId, { messages: newMessages });
  };

  const hiddenRetryMessage = (): ChatMessage => ({
    role: userRole,
    content: 'Please retry',
    type: HIDDEN_TYPE_MSG,
    timestamp: new Date().toISOString(),
  });

  const handleRetryMessage = async () => {
    setBotIsTyping(true);
    const slicedMessages = messages.slice(0, -1);
    const newMessages = [...slicedMessages, hiddenRetryMessage()];
    setMessages(newMessages);
    const responseToMessage = await sendMessage(newMessages);
    if (responseToMessage) {
      setMessages([...newMessages, responseToMessage]);
    } else {
      toast.error('Error retrying');
    }
    setBotIsTyping(false);
  };

  // this should edit the message, though let's not do it yet
  const handleEditMessage = () => {
    console.log('editing message');
  };

  const hiddenContinueMessage = (): ChatMessage => ({
    role: userRole,
    content: 'Please continue, avoid repeating yourself',
    type: HIDDEN_TYPE_MSG,
    timestamp: new Date().toISOString(),
  });

  const handleContinue = async () => {
    setBotIsTyping(true);
    const messagesToSend = [...messages, hiddenContinueMessage()];
    const responseToMessage = await sendMessage(messagesToSend);
    if (responseToMessage) {
      setMessages([...messagesToSend, responseToMessage]);
    }
    setBotIsTyping(false);
  };

  const showActionButtons = !isUser && isLastMessage;

  const isOnboardingMessage = message.type === ONBOARDING_TYPE_MSG;
  const hideMessage =
    message.type === HIDDEN_TYPE_MSG || message.type === FIRST_HIDDEN_MESSAGE;

  if (hideMessage) return null;
  return (
    <MessageContainer isUser={isUser}>
      <MessageSender>{roleToRender}</MessageSender>
      <MessageContent
        isUser={isUser}
        dangerouslySetInnerHTML={{ __html: processContent(message.content) }}
      />

      {showActionButtons && !botIsTyping && !isOnboardingMessage && (
        <ActionButtonsInMessage
          handleRetryMessage={handleRetryMessage}
          handleEditMessage={handleEditMessage}
          handleContinue={handleContinue}
        />
      )}
    </MessageContainer>
  );
};

export default MessageBubble;

const MessageContainer = styled.div<{ isUser?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 4px;
  max-width: 82vw;

  ${desktopMediaQuery} {
    max-width: 35vw;
  }

  margin-left: ${({ isUser }) => (isUser ? 'auto' : '0')};

  position: relative;
`;

const MessageSender = styled.div`
  font-size: 14px;
  margin-left: 4px;
`;

const processContent = (content: string) => {
  // Sanitize content to prevent XSS attacks
  const sanitizedContent = content.replace(/</g, '&lt;').replace(/>/g, '&gt;');

  // Replace *text* with <em>*text*</em>
  const withEmphasis = sanitizedContent.replace(/\*(.*?)\*/g, '<em>*$1*</em>');

  // Replace newlines with <br>
  return withEmphasis.replace(/\n/g, '<br />');
};

const MessageContent = styled.div<{ isUser?: boolean }>`
  animation: ${fadeInWithBounce} 0.3s ease-in-out;
  background-color: ${({ isUser }) =>
    isUser ? USER_MESSAGE_COLOR : BOT_MESSAGE_COLOR};
  border-radius: ${({ isUser }) =>
    isUser ? '16px 0px 16px 16px' : '0px 16px 16px 16px'};
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  color: ${PRIMARY_TEXT_COLOR};

  font-size: 16px;

  ${desktopMediaQuery} {
    font-size: 18px;
  }
`;
