import styled from 'styled-components';
import { SmallStyledButton } from '../../components/common/StyledButton';
import { FaRedo } from 'react-icons/fa';
import useSendMessage from '../../hooks/chat/useSendMessage';
import { useChatContext } from '../../context/ChatContextProvider';
import { toast } from 'react-toastify';

interface RetrySendingMessageButtonProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

const RetrySendingMessageButton = ({
  show,
  setShow,
}: RetrySendingMessageButtonProps) => {
  const { activeChatId, chats, updateChat, setBotIsTyping, botIsTyping } = useChatContext();
  const sendMessage = useSendMessage();

  const handleRetryingSendMessage = async () => {
    const activeChat = chats.find((chat) => chat.id === activeChatId);
    if (!activeChat?.messages.length) return;

    setBotIsTyping(true);
    const messages = activeChat.messages;
    const responseToMessage = await sendMessage(messages);
    
    if (responseToMessage) {
      updateChat(activeChatId, { messages: [...messages, responseToMessage] });
      setShow(false);
    } else {
      toast.error('Error retrying message');
    }
    setBotIsTyping(false);
  };

  if (!show) return null;

  if (botIsTyping) return null;

  return (
    <Container>
      <Button onClick={handleRetryingSendMessage}>
        <FaRedo />
        Retry sending message
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0;
`;

const Button = styled(SmallStyledButton)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export default RetrySendingMessageButton;
