// Extend the Window interface to include Plausible's `plausible` function
declare global {
  interface Window {
    plausible: (
      eventName: string,
      options?: { props?: Record<string, any>; callback?: () => void },
    ) => void;
  }
}

/**
 * Tracks events using Plausible analytics.
 * @param eventName The name of the event to track.
 */
const trackEvent = (eventName: string) => {
  if (window.plausible) {
    window.plausible(eventName);
  }
};

export const trackAdTurboTriggered = () => {
  //trackEvent('ad-turbo-triggered');
};

export const trackLandingGenerateClicked = () => {
  trackEvent('landing-generate-clicked');
};

export const trackHomePageGenerateStarted = () => {
  //temporarily disabled
  //trackEvent('home-page-generate-started');
};

export const trackAdvancedGeneratorStarted = () => {
  //temporarily disabled
  //trackEvent('advanced-generator-started');
};

export const trackTagGeneratorStarted = () => {
  //temporarily disabled
  //trackEvent('tag-generator-started');
};

export const trackSuperv1MiniAdTurboStarted = () => {
  trackEvent('superv1mini-ad-turbo-started');
};

export const trackSuperv1MiniStarted = () => {
  trackEvent('superv1mini-started');
};

export const trackSuperv1Started = () => {
  trackEvent('superv1-started');
};

export const trackLegacyEngineAdStarted = () => {
  trackEvent('legacy-engine-ad-started');
};

export const trackLegacyEngineTurboStarted = () => {
  trackEvent('legacy-engine-turbo-started');
};

export const trackLegacyEngineNormalStarted = () => {
  trackEvent('legacy-engine-normal-started');
};


export const trackOpenInAdvancedGeneratorClicked = () => {
  //trackEvent('open-in-advanced-generator-clicked');
};

export const trackFirstTimeUserClicksOpenTagGen = () => {
  //trackEvent('first-time-user-clicks-open-tag-gen');
};

// NOT USED ANYMORE
export const trackClaimedCredits = () => {
  trackEvent('claimed-credits');
};

/*
THIS IS ALREADY IN BELOW; HERE JUST FOR EASE OF THINKING
export const trackFirstTimeUserBeginsImageGeneration = () => {
  trackEvent('first-time-user-begins-image-generation');
};
*/
