import styled from 'styled-components';
import { BACKGROUND_COLOR, PRIMARY_COLOR } from '../../constants';
import { GenericSetState } from '../../types';
import { StyledButton } from '../common/StyledButton';
import TransitioningModal from './TransitioningModal';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';

interface I {
  showModal: boolean;
  setShowModal: GenericSetState<boolean>;
}

const SomethingWentWrongModal = ({ showModal, setShowModal }: I) => {
  const { uploadedImageUrl } = useImageGenerationSettingsContext();
  const handleClose = () => {
    setShowModal(false);
  };

  /*
  const { loggedInUser } = useLoggedInUserContext();
  const isGoldUser = loggedInUser?.type === "gold";
  const textToShow = isGoldUser
    ? "Try clearing your browser cache and cookies. If the issues still persist, please contact me at support@onlyfakes.app and I'll make sure to fix it up for you. So sorry 🙇"
    : "Ongoing server issues on Free users' servers. Expect temporary problems here and there until new servers are fully functional. If this keeps happening, please refresh your page. If that doesn't help either, try clearing your cache. If the problem persists, please contact me at support@onlyfakes.app or on #bug-reports channel on Discord. Sorry 🙇";
    */

  const textToShow = !!uploadedImageUrl
    ? "Oops! Something went wrong 😔. It might be due to the image you uploaded. Please try a different image or proceed without one. We're aware of this bug and are working on it. Thanks for your patience! 🙇 PS. if you lost credits due to this, contact us providing username and how many credits you lost."
    : 'An error happened, try again! If this keeps happening, try another engine. If the issues still persist, please contact me at hello@uncensoredai.io or Discord. Sorry 🙇 PS. if you lost credits due to this, contact us providing username and how many credits you lost.';
  return (
    <Modal
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 100,
        },
        content: {
          backgroundColor: BACKGROUND_COLOR,
          padding: '32px',
          width: '80%',
          maxWidth: '600px',
          margin: 'auto',
          marginTop: '20%',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      // click anywhere to close modal
      onRequestClose={handleClose}
    >
      <TitleText>Sorry, something went wrong 😭</TitleText>
      <ModalText>{textToShow}</ModalText>
      <AcceptButton onClick={handleClose}>OK!</AcceptButton>
    </Modal>
  );
};

/*
Old text
      <ModalText>
        Please try again or use a different engine. If the problem persists,
        please contact me at support@onlyfakes.app or through Discord
        #bug-reports channel
      </ModalText>
      <ModalText>
        <b>
          Often the error is fixed by clearing the cache of your browser. This
          makes sure you have the latest version of the site.
        </b>
      </ModalText>
*/

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 50px;
`;

const ModalText = styled.div`
  font-size: 16px;
  color: #e8eaed;
  text-align: center;
  margin-bottom: 16px;
`;

const TitleText = styled(ModalText)`
  font-size: 24px;
`;

const AcceptButton = styled(StyledButton)`
  border-color: ${PRIMARY_COLOR};
`;

export default SomethingWentWrongModal;
