import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DESKTOP_WIDTH, MAX_WIDTH } from '../../constants';
import { Size } from '../../types';
import PromptInputBox from './PromptInputBox';
import AdvancedOptions from './AdvancedOptions/AdvancedOptions';
import GeneratedImagesContainer from './GeneratedImagesContainer';
import {
  getNewNegativePrompt,
  cleanUpPrompt,
  waitSeconds,
} from '../../components/helpers';
import ButtonsBelowImage from './ButtonsBelowImage/ButtonsBelowImage';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import SomethingWentWrongModal from '../../components/Modals/SomethingWentWrongModal';
import { removeImageFromLocalStorage } from '../../localStorage/imageStorage';
import {
  desktopMediaQuery,
  desktopStyleToAdd,
  getIsOnDesktop,
} from '../../styleHelpers';
import useHandleUpdatingTitle from '../../hooks/misc/useHandleUpdatingTitle';
import useHandleSendPrompt from '../../hooks/images/useHandleSendPrompt';
import {
  isSuperEngine,
  superEngineV1,
  superEngineV1Mini,
  transformOldEngineToNew,
} from '../../utils/engineHelpers';
import StylesSection from './StylesSection/StylesSection';
import InstructionsText from './InstructionsText';
import { getDefaultStyleViaId } from '../../utils/imageStylesHelpers';
import MainButtons from './MainButtons';
import OptionalPart from './OptionalPart';

import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import {
  MultipleTabsDetectedComponent,
  useMultipleTabsHandler,
} from './MultipleTabsDetected';
import CreateVariationsSection from './CreateVariationsSection/CreateVariationsSection';
import HelpPopup from '../../components/Modals/HelpPopup';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import { fadeIn } from '../../components/ImageStuff/animations';
import NeedSupport from './NeedSupport';
import { toast } from 'react-toastify';
import {
  getLastUsedStyleFromLocalStorage,
  setLastUsedStyleToLocalStorage,
  setRecentlyUsedStylesToLocalStorage,
} from '../../localStorage/stylesStorage';
import { scrollToTop } from '../../hooks/misc/helpers';
import BannerAd from '../../components/Ads/BannerAd';
import useHandleSendFluxImageRequest from '../../hooks/images/useHandleSendFluxImageRequest';
import { useModalsContext } from '../../context/ModalsContextProvider';
import { trackAdvancedGeneratorStarted } from '../../utils/analytics';

const AdvancedModeTab = () => {
  const showMultipleTabsError = useMultipleTabsHandler();

  const { loggedInUser } = useLoggedInUserContext();

  useHandleUpdatingTitle();

  const handleSetPrompt = (prompt: string) => {
    activeImage.selectedTags = [];
    setSelectedTags([]);
    setIsSeedFrozen(true);
    setUserPrompt(prompt);
  };

  const handleSetNegativePrompt = (negativePrompt: string) => {
    activeImage.selectedTags = [];
    setSelectedTags([]);
    setIsSeedFrozen(true);
    setNegativePrompt(negativePrompt);
  };

  const { isSeedFrozen, setIsSeedFrozen, setLoading } = useGeneralContext();

  const handleSetSize = (size: Size) => {
    setIsSeedFrozen(true);
    setSize(size);
  };

  const {
    showSomethingWentWrong,
    setShowSomethingWentWrong,
    loading,
    images,
    setImages,
    activeImage,
  } = useGeneralContext();

  const [showBannerAd, setShowBannerAd] = useState(false);

  const {
    userPrompt,
    setUserPrompt,
    negativePrompt,
    setNegativePrompt,
    seedState,
    setSeed,
    size,
    setSize,
    engine,
    setEngine,
    cfg,
    setCfg,
    amountToGenerate,
    setAmountToGenerate,
    speedMode,
    setSpeedMode,
    uploadedImageUrl,
    setUploadedImageUrl,
    setDenoisingStrength,
    selectedStyle,
    setSelectedStyle,
    isCreatingVariations,
    setIsCreatingVariations,
    setSelectedTags,
  } = useImageGenerationSettingsContext();

  const { setShowHintToRegisterModal } = useModalsContext();

  const handleSendPromptHook = useHandleSendPrompt({
    isSeedFrozen,
    cfg,
    amountToGenerate,
    speedMode,
  });

  const handleSendFluxImageRequest = useHandleSendFluxImageRequest();

  const handleSendPrompt = async () => {
    setShowBannerAd(false);

    trackAdvancedGeneratorStarted();

    if (
      engine?.modelId &&
      (engine.modelId === superEngineV1.modelId ||
        engine.modelId === superEngineV1Mini.modelId)
    ) {
      handleSendFluxImageRequest();
    } else {
      handleSendPromptHook();
    }

    scrollToTop({ topValue: 60 });

    if (speedMode === 'turbo') return;
    await waitSeconds(1);
    setShowBannerAd(true);
  };

  const handleRemovePhoto = () => {
    const trackIdOfActiveImage = activeImage.trackId;
    const confirmRemove = window.confirm(
      'Are you sure you want to remove this photo?',
    );
    if (!confirmRemove) {
      return;
    }

    if (images.length === 0 && !activeImage) {
      return;
    }
    if (loading) return;

    setImages((prevImages) =>
      prevImages.filter((image) => image.trackId !== trackIdOfActiveImage),
    );

    setLoading(false);
    removeImageFromLocalStorage(activeImage);
  };

  const isOnDesktop = getIsOnDesktop();

  // If has generated images, show some of the buttons in a different order
  const hasGeneratedImages = images.length > 0;

  // hacks to make sure the advanced container is in the right place on desktop
  const getTopToAddToAdvancedContainer = () => {
    if (isSuperEngine(engine)) return 64;

    const getHeight = (value: number): number => {
      // Base height and character count at which height starts to increase
      const baseHeight = 50; // Minimum height
      const minHeightIncrement = 10; // Height increment for the first threshold
      const heightIncrement = 15; // Additional height increment for each threshold
      const baseIncrementThreshold = 35; // Character count at which height starts to increase
      const incrementStep = 38; // Character count step for each height increment

      if (value <= baseIncrementThreshold) {
        return baseHeight;
      } else {
        // Calculate extra height based on character count beyond the base increment threshold
        const extraHeight =
          minHeightIncrement +
          Math.ceil((value - baseIncrementThreshold) / incrementStep) *
            heightIncrement;
        return baseHeight + extraHeight;
      }
    };
    const hasUploadedImage = uploadedImageUrl !== '';
    const getNegPromptTotalLenght = () => {
      const styleNegativePromptLength =
        selectedStyle?.negativePrompt?.length ?? 0;
      return negativePrompt.length + styleNegativePromptLength;
    };

    if (isOnDesktop) {
      const negPromptTotalLength = getNegPromptTotalLenght();
      const heightToAdd = getHeight(negPromptTotalLength);
      let top = heightToAdd;
      if (hasUploadedImage && !isCreatingVariations) {
        return top + 600;
      } else if (hasUploadedImage && isCreatingVariations) {
        return top + 100;
      } else {
        return top + 380;
      }
    }
    return 0;
  };

  const topToAddToAdvancedContainer = getTopToAddToAdvancedContainer();

  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const handleClickCreateVariations = () => {
    if (loading) return;
    if (!loggedInUser) {
      setShowHintToRegisterModal(true);
      return;
    }

    if (isSuperEngine(activeImage.engine)) {
      toast.error('Variations not yet in use in super engines');
      return;
    }

    setIsCreatingVariations(true);
    setDenoisingStrength(0.8);
    setUploadedImageUrl(activeImage.imageUrl);
    window.scrollTo({
      top: 660,
      behavior: 'smooth',
    });

    const hasSeenCreateVariationsHelpPopup = localStorage.getItem(
      'hasSeenCreateVariationsHelpPopup',
    );
    if (hasSeenCreateVariationsHelpPopup) {
      return;
    }
    setShowHelpPopup(true);
    localStorage.setItem('hasSeenCreateVariationsHelpPopup', 'true');
  };

  const handleSettingSelectedStyleDuringActiveImageChange = async () => {
    if (activeImage?.style) {
      setSelectedStyle(activeImage.style);

      setLastUsedStyleToLocalStorage(activeImage.style);
      setRecentlyUsedStylesToLocalStorage(activeImage.style);
    } else {
      const defaultNoneStyle = getDefaultStyleViaId('0');
      setSelectedStyle(defaultNoneStyle);
    }
  };

  useEffect(() => {
    const lastUsedStyleParsed = getLastUsedStyleFromLocalStorage();
    if (lastUsedStyleParsed) {
      setSelectedStyle(lastUsedStyleParsed);
      if (lastUsedStyleParsed?.engine) {
        setEngine(lastUsedStyleParsed.engine);
      }
    }
  }, []);

  // If loggedInUser with 0 credits, set to turbo ads
  useEffect(() => {
    if (loggedInUser && loggedInUser?.credits === 0) {
      setSpeedMode('adTurbo');
    }
  }, [loggedInUser]);

  // TODO: refactor this piece of crap :D
  // if active image changes, change prompts etc
  useEffect(() => {
    if (!activeImage.imageUrl) {
      return;
    } else {
      const transformedEngine = transformOldEngineToNew(activeImage.engine);
      setEngine(transformedEngine);

      setSize({
        width: String(activeImage.width),
        height: String(activeImage.height),
      });

      setCfg(activeImage.guidanceScale);

      setIsSeedFrozen(false);

      const newPrompt = cleanUpPrompt(activeImage.prompt);
      setUserPrompt(newPrompt);

      const newNegativePrompt = getNewNegativePrompt(
        activeImage.negativePrompt,
      );
      setNegativePrompt(newNegativePrompt);

      setSeed(activeImage.seed);

      handleSettingSelectedStyleDuringActiveImageChange();

      const uploadedImageUrl = activeImage.uploadedImageUrl;
      if (uploadedImageUrl) {
        setUploadedImageUrl(uploadedImageUrl);
      } else if (uploadedImageUrl === '') {
        setIsCreatingVariations(false);
      }

      if (activeImage.isCreatingVariations) {
        setDenoisingStrength(0.8);
        setIsCreatingVariations(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeImage]);

  useEffect(() => {
    scrollToTop({ topValue: 62 });
  }, []);

  useEffect(() => {
    if (activeImage.selectedTags && activeImage.selectedTags.length !== 0) {
      if (loggedInUser?.type !== 'gold') {
        toast.error(
          'Edit a Magic Mode image using Advanced Mode by getting Gold Subscription ⭐ Emptying settings...',
        );
        setUserPrompt('');
        setNegativePrompt('');
        setSelectedTags([]);
        /*
      const trackIdOfActiveImage = activeImage.trackId;
      setImages((prevImages) =>
        prevImages.filter((image) => image.trackId !== trackIdOfActiveImage),
      );
      */
      }
    }
  }, [activeImage.selectedTags]);

  if (showMultipleTabsError) {
    return <MultipleTabsDetectedComponent />;
  }

  return (
    <Container
      onKeyDown={(e) => {
        if (window.innerWidth < DESKTOP_WIDTH) {
          return;
        }
        if (e.key === 'Enter' && e.shiftKey) {
          return;
        }
        if (e.key === 'Enter') {
          handleSendPrompt();
        }
      }}
    >
      <SomethingWentWrongModal
        showModal={showSomethingWentWrong}
        setShowModal={setShowSomethingWentWrong}
      />

      <HelpPopup showModal={showHelpPopup} setShowModal={setShowHelpPopup} />

      <BasePartContainer>
        {hasGeneratedImages ? (
          <>
            {showBannerAd && <BannerAd />}
            <GeneratedImagesContainer hideRandomTip />
            <ButtonsBelowImage
              handleRemovePhoto={handleRemovePhoto}
              handleClickCreateVariations={handleClickCreateVariations}
            />
            <MainButtons
              speedMode={speedMode}
              setSpeedMode={setSpeedMode}
              amountToGenerate={amountToGenerate}
              setAmountToGenerate={setAmountToGenerate}
              handleSendPrompt={handleSendPrompt}
              engine={engine}
            />
          </>
        ) : (
          <InstructionsText />
        )}

        {isCreatingVariations && <CreateVariationsSection />}

        <NormalSettingsContainer>
          {
            // if hasGeneratedImages, then Prompt is first, styles second. Otherwise the other way around

            hasGeneratedImages ? (
              <>
                <PromptInputBox
                  prompt={userPrompt}
                  handleSetPrompt={handleSetPrompt}
                />
                <StylesSection
                  selectedStyle={selectedStyle}
                  setSelectedStyle={setSelectedStyle}
                  setEngine={setEngine}
                />
              </>
            ) : (
              <>
                <StylesSection
                  selectedStyle={selectedStyle}
                  setSelectedStyle={setSelectedStyle}
                  setEngine={setEngine}
                />
                <PromptInputBox
                  prompt={userPrompt}
                  handleSetPrompt={handleSetPrompt}
                />
              </>
            )
          }
        </NormalSettingsContainer>

        {
          // The main buttons will be moved a after user has generated images. First they are here below styles
          !hasGeneratedImages && (
            <MainButtons
              speedMode={speedMode}
              setSpeedMode={setSpeedMode}
              amountToGenerate={amountToGenerate}
              setAmountToGenerate={setAmountToGenerate}
              handleSendPrompt={handleSendPrompt}
              engine={engine}
            />
          )
        }

        <OptionalPart
          negativePrompt={negativePrompt}
          handleSetNegativePrompt={handleSetNegativePrompt}
          selectedStyle={selectedStyle}
        />
      </BasePartContainer>

      <AdvancedContainer
        topToAddToAdvancedContainer={topToAddToAdvancedContainer}
      >
        <AdvancedOptions
          seed={seedState}
          setSeed={setSeed}
          size={size}
          handleSetSize={handleSetSize}
          isSeedFrozen={isSeedFrozen}
          setIsSeedFrozen={setIsSeedFrozen}
          cfg={cfg}
          setCfg={setCfg}
          engine={engine}
          setEngine={setEngine}
        />
      </AdvancedContainer>

      <NeedSupport />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ${desktopStyleToAdd}

  animation: ${fadeIn} 1s ease-in-out;
`;

const BasePartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;

  ${desktopMediaQuery} {
    margin-left: -40px;
    width: 100%;
  }
`;

const AdvancedContainer = styled.div<{ topToAddToAdvancedContainer: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 8px;
  margin-bottom: 80px;

  @media (min-width: ${DESKTOP_WIDTH}px) {
    width: 100%;
    position: absolute;
    right: 0;
    top: ${(props) => props.topToAddToAdvancedContainer}px;
    max-width: 450px;
    margin: 64px;
    margin-right: 3.5vw;
  }
`;

const NormalSettingsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default AdvancedModeTab;
