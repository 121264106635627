import { useState, useEffect } from 'react';
import styled from 'styled-components';
import TopBarInConversation from './TopBarInConversation';
import ChatMessages from './ChatMessages';
import { useChatContext } from '../../context/ChatContextProvider';
import ChatInput from './ChatInput';
import {
  FIRST_HIDDEN_MESSAGE,
  userRole,
} from '../../chatConstants';
import { ChatMessage } from '../../types';
import ChatStorage from '../../localStorage/chatStorage';
import { waitSeconds } from '../../components/helpers';
import useSendMessage from '../../hooks/chat/useSendMessage';
import { desktopMediaQuery } from '../../styleHelpers';
import { useNavigate } from 'react-router-dom';
import { CHAT_LIST_PATH } from '../../pathNames';
import ChatSettingsModal from '../../components/Modals/ChatModals/ChatSettingsModal/ChatSettingsModal';
import ShowChatImagesModal from '../../components/Modals/ChatModals/ShowChatImagesModal/ShowChatImagesModal';
import ChatSettingsStorage from '../../localStorage/chatSettingsStorage';
import ChatFirstTimeTipsModal from '../../components/Modals/ChatModals/ChatFirstTimeTipsModal/ChatFirstTimeTipsModal';
import RetrySendingMessageButton from './RetrySendingMessageButton';

const getHiddenGreetingMessage = () => ({
  role: userRole,
  content: `Greet the user in a way that fits the scenario and the bot's persona. Make it engaging so the user wants to answer to you in a creative way. Don't break character.`,
  type: FIRST_HIDDEN_MESSAGE,
  timestamp: new Date().toISOString(),
});

const ChatConversation = () => {
  const navigate = useNavigate();
  const {
    activeChatId,
    chats,
    updateChat,
    botIsTyping,
    setBotIsTyping,
    isFocusedOnKeyboard,
  } = useChatContext();

  const activeChat = chats.find((chat) => chat.id === activeChatId);
  const chatSettingsStorage = new ChatSettingsStorage();

  const [messages, setMessages] = useState<ChatMessage[]>(
    activeChat?.messages || [],
  );
  const [chatInputValue, setChatInputValue] = useState('');
  const placeholder = 'Type a message...';

  const chatStorage = new ChatStorage();
  const sendMessage = useSendMessage();

  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [showFirstTimeTipsModal, setShowFirstTimeTipsModal] = useState(false);
  const [shouldShowRetry, setShouldShowRetry] = useState(false);

  useEffect(() => {
    if (activeChat) {
      setMessages(activeChat.messages);
    } else {
      navigate(CHAT_LIST_PATH);
      setMessages([]);
    }
  }, [activeChat]);

  // if messages are empty, i.e. just beginning the chat for the first time, get a message from the bot
  useEffect(() => {
    if (activeChat && activeChat.messages.length === 0) {
      setBotIsTyping(true);

      const getBotMessage = async () => {
        const messageToSendToServer = [getHiddenGreetingMessage()];

        const responseToMessage = await sendMessage(messageToSendToServer);
        if (responseToMessage) {
          const responseWithTimestamp = {
            ...responseToMessage,
            timestamp: new Date().toISOString(),
          };
          chatStorage.addMessage(responseWithTimestamp);
          const messagesWithResponse = [...messages, responseWithTimestamp];
          setMessages(messagesWithResponse);
          updateChat(activeChatId, { messages: messagesWithResponse });
          setShouldShowRetry(false);
        } 
        setBotIsTyping(false);
      };

      getBotMessage();
    }
  }, []);

  useEffect(() => {
    const hasSeenTips = chatSettingsStorage.getHasSeenChatTips();
    if (!hasSeenTips) {
      setShowFirstTimeTipsModal(true);
    }
  }, []);

  const handleCloseFirstTimeTips = () => {
    setShowFirstTimeTipsModal(false);
    chatSettingsStorage.setHasSeenChatTips(true);
  };

  const handleSendMessage = async () => {
    if (chatInputValue.trim() === '') {
      alert('Please write a message');
      return;
    }
    if (botIsTyping) return;

    const messageToAdd: ChatMessage = {
      role: userRole,
      content: chatInputValue,
      timestamp: new Date().toISOString(),
    };

    const newMessages = [...messages, messageToAdd];
    setMessages(newMessages);
    updateChat(activeChatId, { messages: newMessages });
    setChatInputValue('');

    await waitSeconds(0.3);
    setBotIsTyping(true);

    chatStorage.addMessage(messageToAdd);

    const responseToMessage = await sendMessage(newMessages);
    if (responseToMessage) {
      const responseWithTimestamp = {
        ...responseToMessage,
        timestamp: new Date().toISOString(),
      };
      chatStorage.addMessage(responseWithTimestamp);
      const messagesWithResponse = [...newMessages, responseWithTimestamp];
      setMessages(messagesWithResponse);
      updateChat(activeChatId, { messages: messagesWithResponse });
      setShouldShowRetry(false);
    } else {
      setShouldShowRetry(true);
    }

    setBotIsTyping(false);
  };

  return (
    <Container isFocusedOnKeyboard={isFocusedOnKeyboard}>
      <TopBarInConversation 
        setShowSettingsModal={setShowSettingsModal}
        setShowImagesModal={setShowImagesModal}
        setShowFirstTimeTipsModal={setShowFirstTimeTipsModal}
      />

      <ChatMessages messages={messages} botIsTyping={botIsTyping} />

      <RetrySendingMessageButton
        show={shouldShowRetry}
        setShow={setShouldShowRetry}
      />

      <ChatInput
        chatInputValue={chatInputValue}
        setChatInputValue={setChatInputValue}
        handleSendMessage={handleSendMessage}
        placeholder={placeholder}
      />

      <ChatSettingsModal 
        show={showSettingsModal}
        setShow={setShowSettingsModal}
      />
      <ShowChatImagesModal
        show={showImagesModal}
        setShow={setShowImagesModal}
      />
      <ChatFirstTimeTipsModal
        show={showFirstTimeTipsModal}
        setShow={handleCloseFirstTimeTips}
      />
    </Container>
  );
};

const Container = styled.div<{ isFocusedOnKeyboard: boolean }>`
  display: flex;
  flex-direction: column;

  height: 85vh;
  width: 100%;
  ${desktopMediaQuery} {
    margin-left: 13vw;
    margin-top: -32px;
  }

  max-width: 700px;

  overflow-y: hidden;

  padding-bottom: ${({ isFocusedOnKeyboard }) =>
    isFocusedOnKeyboard ? '-8px' : '16px'};

  // if focused 12px, 2px
  margin-top: ${({ isFocusedOnKeyboard }) =>
    isFocusedOnKeyboard ? '12px' : '2px'};
`;

export default ChatConversation;
